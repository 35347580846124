import { API } from "../../../config";
import AxiosUtils from "../../../utils/AxiosUtils";
import { mockAPIPath, serverAPIPath } from "../config/apiPath";
import Cookies from "../../../utils/Cookies";
import { LIST_LIMIT } from "../../../constants/constants";
import confirmShipmentType from "../../../interfaces/confirmShipmentInterface";
import  { OMSShippedTypeWithPaginationType } from "../../../interfaces/omsShippedInterface";
import { OMSReasonType } from "../../../interfaces/omsReasonInterface";

class omsDownloadService {
    private payload: object;
    private isHitDummyURL: boolean;
    private headers: object;

    constructor(payload = {}, isHitDummyURL = false) {
        this.payload = payload;
        this.isHitDummyURL = isHitDummyURL;
        this.headers = { "access-token": new Cookies("access-token").read() };
    }
    async omsDownloadList(
        page_id: number,
        search?: string,
        dateRange?:any,
        toParam="",
        fromParam="",
        order?:any,
        statusCode?:any,
        limit = LIST_LIMIT.OMS_SHIPPED_LIST_LIMIT
    ) {
        const response = this.isHitDummyURL
            ? await new AxiosUtils(
                `${mockAPIPath.OMS_MANAGE_ORDER}`,
                this.payload,
                this.headers
            ).get()
            : await new AxiosUtils(
                `${API.BASE_URL}${serverAPIPath.OMS_MANAGE_ORDER}?page=${page_id}&search=${search}&dateRange=${dateRange}&to=${toParam}&from=${fromParam}&order=${order}&statusCode=${statusCode == "null" ? "" : statusCode}&limit=${limit}`,
                this.payload,
                this.headers
            ).get();
        if (response?.data?.success) {
            const { data, currentPage, totalPages, currentTotalCount, totalCount, totalBackOrderCount, totalPreparingShipmentCount } =
                response.data.data;
            
            const OmsShippedData: OMSShippedTypeWithPaginationType = {
                omsShippedLogs: data,
                pagination: {
                    currentPage,
                    totalPages,
                    currentTotalCount,
                    totalCount,
                },
                totalBackOrderCount: totalBackOrderCount,
                totalPreparingShipmentCount: totalPreparingShipmentCount
            };
            return OmsShippedData;
        } else if (response?.success == false) {
            throw new Error(response?.message);
        } else {
            throw new Error(response);
        }
    }

    async omsRefund(orderID:any) {
        const response = this.isHitDummyURL
            ? await new AxiosUtils(
                `${mockAPIPath.OMS_REFUND}`,
                this.payload,
                this.headers
            ).get()
            : await new AxiosUtils(
                `${API.BASE_URL}${serverAPIPath.OMS_REFUND}/${orderID}`,
                this.payload,
                this.headers
            ).get();
        if (response?.data?.success) {
            const OmsConfirmData: confirmShipmentType = response?.data?.data;
            return OmsConfirmData;
        } else if (response?.success == false) {
            throw new Error(response?.message);
        } else {
            throw new Error(response);
        }
    }

    async refundOrderReason() {
        const response = this.isHitDummyURL
            ? await new AxiosUtils(
                `${mockAPIPath.OMS_REFUND_ORDER_REASON}`,
                this.payload,
                this.headers
            ).get()
            : await new AxiosUtils(
                `${API.BASE_URL}${serverAPIPath.OMS_REFUND_ORDER_REASON}`,
                this.payload,
                this.headers
            ).get();
        if (response?.data?.success) {
            const cancelOrderReasonData: OMSReasonType[] = response?.data?.data;
            return cancelOrderReasonData;
        } else if (response?.success == false) {
            throw new Error(response?.message);
        } else {
            throw new Error(response);
        }
    }

    async dowloadCsv(
        search?: string,
        dateRange?:any,
        toParam="",
        fromParam="",
        order?:any,
        statusCode?:any,
        limit = LIST_LIMIT.OMS_SHIPPED_LIST_LIMIT
    ) {
        const response = this.isHitDummyURL
            ? await new AxiosUtils(
                `${mockAPIPath.OMS_MANAGE_ORDER}`,
                this.payload,
                this.headers
            ).get()
            : await new AxiosUtils(
                `${API.BASE_URL}${serverAPIPath.OMS_MANAGE_ORDER}?&search=${search}&dateRange=${dateRange}&to=${toParam}&from=${fromParam}&order=${order}&statusCode=${statusCode == "null" ? "" : statusCode}&limit=${limit}&download=true`,
                this.payload,
                this.headers
            ).get();
        if (response?.data?.success) {
            const { data, currentPage, totalPages, currentTotalCount, totalCount, totalBackOrderCount, totalPreparingShipmentCount } =
                response.data.data;
            
            const OmsShippedData: OMSShippedTypeWithPaginationType = {
                omsShippedLogs: data,
                pagination: {
                    currentPage,
                    totalPages,
                    currentTotalCount,
                    totalCount,
                },
                totalBackOrderCount: totalBackOrderCount,
                totalPreparingShipmentCount: totalPreparingShipmentCount
            };
            return OmsShippedData;
        } else if (response?.success == false) {
            throw new Error(response?.message);
        } else {
            throw new Error(response);
        }
    }
   
}
export default omsDownloadService;