import React, { useEffect, useState } from "react";
import {
    Button,
    Container,
    Typography,
    Box,
    Grid,
    Divider,
    Checkbox,
    IconButton,
    InputBase,
    OutlinedInput,
} from "@mui/material";
import { useAppDispatch } from "../../../redux/hooks";
import Table from "@mui/material/Table";
import Select from "@mui/material/Select";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import { ROUTES } from "../../../constants/routes";
import Skeleton from "../../common/Skeleton";
import MenuItem from "@mui/material/MenuItem";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import omsShippedService from "../service/omsShippedService";
import HeaderTitle from "../../common/HeaderTitle";
import ShipmentDataService from "../service/shipmentDataService";
import { useParams } from "react-router";
import Utility from "../../../utils/Utility";
import { commonActions } from "../../../redux/features/common/commonSlice";
import CONSTANTS, { CONSUMPTION_TAX_CATEGORY, REFUND_REASON_ID } from "../../../constants/constants";
import ModalUtility from "../../../utils/modalUtility";
import CommonButton from "../../common/Button";

const RefundOrder = (props: any) => {

    const { showSnackbar, handleLoader } = props;

    const params = useParams();
    const [t] = useTranslation();
    const navigate = useNavigate();
    const dispatch = useAppDispatch();

    let totalPriorAmount = 0;
    const [shippedData, setShippedData] = useState<any>([]);
    const [Reason, setReasons] = useState<any>([]);
    const [alertModalOpen, setAlertModalOpen] = useState(false);
    const [currentPage, setCurrentPage] = useState<any>(1);
    const [totalPages, setTotalPages] = useState<any>(0);
    const [RefundData, setRefundData] = useState<any>([]);
    const [refundReason, setRefundReason] = useState<any>();
    const [point, setPoint] = useState<any>([0]);
    const [textValue, setTextValue] = useState<any>([0]);
    const [productPriceAmount, setProductPriceAmount] = useState<any>([]);
    const [maxProductPriceAmount, setMaxProductPriceAmount] = useState<any>([0]);
    const [otherConcessionAmount, setOtherConcessionAmount] = useState<any>([0]);
    const [maxOtherConcessionAmount, setMaxOtherConcessionAmount] = useState<any>([0]);
    const [totalAmount, setTotalPoint] = useState<number>(0);
    const [getRefundData, setGetRefundData] = useState<any>(0);
    const cainzProductCodes: any[] = [];
    const productRefundAmounts: any[] = [];
    const optionalRefundAmounts: any[] = [];
    const ProductSubtotals: any[] = [];
    const MaxProductSubtotals: any[] = [];
    const MaxSalesVolume: any[] = [];
    const [flag, setFlag] = useState(true);
    const [val, setVal] = useState(false);
    const [flagQuantity, setFlagQuantity] = useState(true);
    const [pointReference, setPointReference] = useState<any>([]);
    const [checked, setChecked] = useState<any>([]);
    const [productPriceInclu, setProductPriceIncul] = useState<any>([0]);
    const [productPriceExclu01, setProductPriceExcul01] = useState<any>([0]);
    const [productPriceExclu02, setProductPriceExcul02] = useState<any>([0]);
    const [memberFlag, setMemberFlag] = useState();

    useEffect(() => {
        setTotalPoints();
    }, [productPriceAmount, otherConcessionAmount, checked]);

    const ValHTML = document.getElementsByClassName("refund_order_text_color");
    useEffect(() => {
        if (ValHTML.length > 0) {
            setVal(true);
        } else {
            setVal(false);
        }
    }, [ValHTML.length, productPriceAmount, otherConcessionAmount, textValue]);


    const setTotalPoints = () => {

        let pointAwarded = 0;
        setTotalPoint(pointAwarded);
        const length =
            productPriceAmount.length > otherConcessionAmount.length
                ? productPriceAmount.length
                : otherConcessionAmount.length;

        for (let i = 0; i < length; i++) {
            const amountValue = (productPriceAmount[i] ? Number(productPriceAmount[i]) : 0) > 0 ? (productPriceAmount[i] ? Number(productPriceAmount[i]) : 0) + (otherConcessionAmount[i] ? Number(otherConcessionAmount[i]) : 0) : 0;
            const maxAmountValue = (maxProductPriceAmount[i] ? Number(maxProductPriceAmount[i]) : 0) > 0 ? (maxProductPriceAmount[i] ? Number(maxProductPriceAmount[i]) : 0) + (maxOtherConcessionAmount[i] ? Number(maxOtherConcessionAmount[i]) : 0) : 0;
            pointAwarded = pointAwarded + (checked[i] ? maxAmountValue : amountValue);
        }
        setTotalPoint(pointAwarded);
    };
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    let AmountRefundPoint = 0;

    const onSumbit = () => {
        shipmentData(currentPage);
    };

    const checkIsAnyCR = () => {
        let checkCR = 0;
        shippedData.map((item: any, productIndex: number) => {

            if (Reason[productIndex] == REFUND_REASON_ID.REFUSAL_TO_RECEIVE || Reason[productIndex] == REFUND_REASON_ID.WRONG_ORDERED_PRODUCT || Reason[productIndex] == REFUND_REASON_ID.OTHER_REASON_CUSTOMER) {
                if (productPriceAmount[productIndex] != null || shippedData[productIndex]?.productPriceSubtotal) {
                    if (item.productRefundAmount != 0) {
                        checkCR++;
                    }
                }
            }
        });

        if (checkCR) {
            return true;
        } else {
            return false;
        }

    };
    const orderID = params.id;
    const handleKeyPress = (e: any) => {
        if (e.key == "Enter") {
            e.preventDefault();
        }
    };

    const shipmentData = (currentPage: number) => {
        handleLoader(true);
        setGetRefundData(1);
        const orderID = params.id;
        const isCR = checkIsAnyCR();
        const payload: any = {
            orderId: orderID,
            pointsReturned: memberFlag == 1 || memberFlag == 2 ? (isCR ? Math.round(AmountRefundPoint) : 0) : 0,
            totalOrderRefundAmount: totalAmount,
            refundProduct: [],
        };
        for (let i = 0; i < checked?.length; i++) {
            const priceAmount = checked[i] ? maxProductPriceAmount : productPriceAmount;
            if (priceAmount[i] != null && priceAmount[i] != 0 && priceAmount[i] != null && textValue[i] != 0 && Reason[i]) {
                const refundProductData: any = {
                    reasonForRefund: Reason[i],
                    returnQuantity: checked[i] ? MaxSalesVolume[i] : textValue[i],
                    productSubtotal: checked[i] ? MaxProductSubtotals[i] : ProductSubtotals[i],
                    productRefundAmount: checked[i] ? maxProductPriceAmount[i] : productPriceAmount[i],
                    optionalRefundAmount: checked[i] ? maxOtherConcessionAmount[i] : otherConcessionAmount[i],
                    cainzProductCode: cainzProductCodes[i],
                };
                payload.refundProduct.push(refundProductData);
            }
        }
        if (payload?.refundProduct?.length > 0) {
            const isHitDummyURL = false;
            new ShipmentDataService(payload, isHitDummyURL)
                .RefundOrder(currentPage)
                .then((res: any) => {
                    handleLoader(false);
                    showSnackbar(res, true);
                    setRefundData(res);
                    navigate(`${ROUTES.OMS}`);
                    dispatch(commonActions.IS_TAB_URL("6"));
                })
                .catch((err: any) => {
                    handleLoader(false);
                    showSnackbar(err?.message, false);
                });
        } else {
            setTimeout(() => {
                handleLoader(false);
            }, 1000);
            showSnackbar(t("oms.please_select_at_least_one_product"), false);
        }
    };


    const handleRefund = () => {
        navigate(`${ROUTES.OMS}`);
    };


    useEffect(() => {
        omsShippedData(currentPage);
        totalPages != 0 ? setShippedData(currentPage) : null; //Correction 1: check value for shippedData
        omsRefundOrderReasonData();
        setGetRefundData(0);
    }, [getRefundData]);

    // Api cal for omsRefund
    const omsShippedData = (currentPage: number) => {
        const orderID = params.id;
        const payload = {};
        const isHitDummyURL = false;
        new omsShippedService(payload, isHitDummyURL)
            .omsRefund(orderID)
            .then((res: any) => {
                setShippedData(res);
                setMemberFlag(res[0]?.memberFlag);
                const checkbox = new Array(res?.length).fill(false); //Set shipped data
                setChecked(checkbox);
                setPointReference(new Array(shippedData.length).fill(0));
                UpdateRefund(res);
            })
            .catch((err: any) => {
                showSnackbar(err?.message, false);
            });
    };

    const omsRefundOrderReasonData = () => {
        const payload = {};
        const isHitDummyURL = false;
        new omsShippedService(payload, isHitDummyURL)
            .refundOrderReason()
            .then((res) => {
                setRefundReason(res);
            })
            .catch((err: any) => {
                showSnackbar(err?.message, false);
            });
    };

    // setting tracking id
    const setReasonId = (event: any, productIndex: number) => {
        if (event.target.name == "trackingId" + productIndex) {
            const trackingIdArray = [...Reason];
            trackingIdArray[productIndex] = Number(event.target.value);
            setReasons(trackingIdArray);
        }
    };

    // setting tracking id
    const setTrackingDataProductPriceAmount = (
        event: any,
        productIndex: number
    ) => {
        event.target.value = event.target.value.replace(/[^0-9||０-９]/g, "");
        if (event.target.name == "trackingId1" + productIndex) {
            const trackingIdArray = [...productPriceAmount];
            trackingIdArray[productIndex] = Number(event.target.value);
            setProductPriceAmount(trackingIdArray);
        }
    };

    const calculateCR = (productPriceInclusiveTax: any, productPriceExclusiveTax: any, productPriceCompulonTax: any, productIndex: number, shipmentData: any, refundQuantity: any) => {

        const tax = Number(shipmentData[productIndex]?.productPrice) * refundQuantity;
        if (shipmentData[productIndex]?.consumptionTaxCategory === CONSUMPTION_TAX_CATEGORY.TAX_CATEGORY_00) {
            productPriceInclusiveTax[productIndex] = tax;
        } else if (shipmentData[productIndex]?.consumptionTaxCategory === CONSUMPTION_TAX_CATEGORY.TAX_CATEGORY_01) {
            productPriceExclusiveTax[productIndex] = Math.floor(Number(tax) / 1.1);
        } else if (shipmentData[productIndex]?.consumptionTaxCategory === CONSUMPTION_TAX_CATEGORY.TAX_CATEGORY_02) {
            productPriceCompulonTax[productIndex] = Math.floor(tax / 1.08);
        }
    };



    const checkIfCR = (productIndex: number, refundReason: string, checkbox: any = [], amount: any, currentProduct: number, shipmentData: any) => {
        let refundAmount = productPriceAmount[productIndex] != null ? productPriceAmount[productIndex] : shipmentData?.productReturnQuantity;
        if (productIndex == currentProduct) {
            refundAmount = amount;
        }

        if (refundReason == REFUND_REASON_ID.REFUSAL_TO_RECEIVE || refundReason == REFUND_REASON_ID.WRONG_ORDERED_PRODUCT || refundReason == REFUND_REASON_ID.OTHER_REASON_CUSTOMER) {
            if ((checkbox[productIndex] ? maxProductPriceAmount[productIndex] : refundAmount) != null) {
                if ((checkbox[productIndex] ? maxProductPriceAmount[productIndex] : refundAmount) != 0) {
                    return true;
                }
            }
        }
    };

    //Calcule Excluding Taxes for SR and CR
    const calculationTax = (productIndex: number, amountValue: number, shipment: any, checkbox: any = [], reason: any = -1, refundQuantityChanged: any = -1) => {
        const refundData = shippedData.length > 0 ? [...shippedData] : [...shipment];
        const priceInclTax00: any = [];
        const priceExclTax01: any = [];
        const priceExclTax02: any = [];
        let response;


        //Tax calculation for SR
        refundData.map((shipmentData: any, index2: number) => {
            let refundReason = Reason[index2] ? Reason[index2] : shipmentData?.refundReason;
            let refundQuantity = textValue[index2] ? Number(textValue[index2]) : shipmentData?.productReturnQuantity;
            refundQuantity = checkbox[index2] ? Number(shipmentData?.salesVolume) : refundQuantity;
            if (index2 == productIndex) {
                refundReason = reason != -1 ? reason : Reason[index2];
                refundQuantity = refundQuantityChanged != -1 ? refundQuantityChanged : Number(textValue[productIndex]);
            }
            if (checkIfCR(index2, refundReason, checkbox, amountValue, productIndex, shipmentData)) {
                calculateCR(priceInclTax00, priceExclTax01, priceExclTax02, index2, refundData, refundQuantity);

            }
        });
        setProductPriceIncul(priceInclTax00);
        setProductPriceExcul01(priceExclTax01);
        setProductPriceExcul02(priceExclTax02);
        return response;
    };


    const calculation = async (
        productIndex: number,
        item: any,
        amountValue: any,
        productPrice: any,
        reasonValue = -1,
        response = 0
    ) => {
        const pointAwared: any = [...point];
        let tax: any;
        if (productPrice !== undefined) {
            tax = calculationTax(productIndex, productPrice, response, checked, reasonValue, amountValue);
        }
        else {
            const pointAwared: any = [...point];
            pointAwared[productIndex] = 0;
            setPoint(pointAwared);
            return pointAwared[productIndex];
        }
    };

    // setting tracking id
    const setOtherconcessionAmountFunctio = (
        event: any,
        productIndex: number
    ) => {
        event.target.value = event.target.value.replace(/[^0-9||０-９]/g, "");
        if (event.target.name == "trackingId3" + productIndex) {
            const trackingIdArray = [...otherConcessionAmount];
            trackingIdArray[productIndex] = Number(event.target.value);
            setOtherConcessionAmount(trackingIdArray);
        }
    };

    const UpdateRefund = (refund: any, isChecked = false, checkBox = false) => {
        const refundQuanity = [...textValue];
        const reasonQuanity = [...Reason];
        const productPrice = [...productPriceAmount];
        const maxProductPrice = [...maxProductPriceAmount];
        const concessionAmount = [...otherConcessionAmount];
        const maxConcessionAmount = [...maxOtherConcessionAmount];
        const pointAwarded: any = [...pointReference];
        let refundedPackages = 0;
        refund?.map(async (item: any, index: number) => {
            refundQuanity[index] = checkBox ? item?.salesVolume : (item?.productReturnQuantity ? item?.productReturnQuantity : item?.salesVolume);
            setTextValue(refundQuanity);
            if (!isChecked) {
                reasonQuanity[index] = Number(item?.refundReason);
                setReasons(reasonQuanity);
            }
            productPrice[index] = item?.productRefundAmount;
            setProductPriceAmount(productPrice);
            maxProductPrice[index] = item?.productPriceSubtotal;
            setMaxProductPriceAmount(maxProductPrice);
            concessionAmount[index] = item?.optionalRefundAmount;
            setOtherConcessionAmount(concessionAmount);
            maxConcessionAmount[index] = item?.individualShippingFee;
            setMaxOtherConcessionAmount(maxConcessionAmount);
            if (item?.totalRefundAmount !== null) {
                refundedPackages = refundedPackages + 1;
                const pointArray = await calculation(index, item, item?.productReturnQuantity, item?.productRefundAmount, Number(item?.refundReason), refund);
                pointAwarded[index] = pointArray;
                setPointReference(pointAwarded);
                setPoint(pointAwarded);
            }
        });
    };

    const handleChecked = (e: any, item: any, checkedIndex: number) => {
        const checkbox = [...checked];
        e.target.checked
            ? checkbox[checkedIndex] = true
            : checkbox[checkedIndex] = false;

        e.target.checked
            ? calculationTax(checkedIndex, shippedData[checkedIndex]?.productPriceSubtotal, 0, checkbox, -1, MaxSalesVolume[checkedIndex])
            : calculationTax(checkedIndex, productPriceAmount[checkedIndex], 0, checkbox, -1, textValue[checkedIndex]);
        setChecked(checkbox);
    };

    const setProductQuantityOption = (itemData: any, productIndex: number) => {
        const menuItem: any = [];
        for (
            let index = 1;
            index <= Number(itemData?.salesVolume);
            index++
        ) {
            menuItem.push(
                <MenuItem key={index} value={index}>
                    {index}
                </MenuItem>
            );
        }
        return menuItem;
    };
    let totalPriceExcludingTax = 0;
    return (
        <>
            <Container className="refund_table_page">
                <HeaderTitle title={t("oms.refund_request")} />
                <Grid container spacing={2}>
                    <Grid item xl={10} lg={9} md={8} sm={12} xs={12}>
                        {shippedData.length == 0 ? (
                            <Skeleton
                                varient="rectangular"
                                width={0}
                                height={484}
                                count="20"
                            />
                        ) : (
                            shippedData?.map((item: any, idIndex: number) => {
                                totalPriorAmount = Number(item?.totalRefundAmount !== null ? item?.totalRefundAmount : totalPriorAmount);
                                cainzProductCodes.push(item?.cainzProductCode);
                                MaxSalesVolume.push(item?.salesVolume);
                                productRefundAmounts.push(item?.productRefundAmount);
                                optionalRefundAmounts.push(item?.optionalRefundAmount);
                                const totalPointValue = Number(item?.productPrice) * (textValue[idIndex] ? Number(textValue[idIndex]) : 0);
                                const concessionMax = Number(item?.individualShippingFee);
                                const amountValue = productPriceAmount[idIndex] > 0 ? (productPriceAmount[idIndex] ? Number(productPriceAmount[idIndex]) : 0) +
                                    (otherConcessionAmount[idIndex] ? Number(otherConcessionAmount[idIndex]) : 0) : 0;
                                const maxAmountValue = maxProductPriceAmount[idIndex] > 0 ? (maxProductPriceAmount[idIndex] ? Number(maxProductPriceAmount[idIndex]) : 0) +
                                    (maxOtherConcessionAmount[idIndex] ? Number(maxOtherConcessionAmount[idIndex]) : 0) : 0;
                                ProductSubtotals.push((productPriceAmount[idIndex] ? Number(productPriceAmount[idIndex]) : 0) +
                                    (otherConcessionAmount[idIndex] ? Number(otherConcessionAmount[idIndex]) : 0));
                                MaxProductSubtotals.push((maxProductPriceAmount[idIndex] ? Number(maxProductPriceAmount[idIndex]) : 0) +
                                    (maxOtherConcessionAmount[idIndex] ? Number(maxOtherConcessionAmount[idIndex]) : 0));

                                totalPriceExcludingTax += Number(productPriceInclu[idIndex] ? productPriceInclu[idIndex] : 0) + Number(productPriceExclu01[idIndex] ? productPriceExclu01[idIndex] : 0) + Number(productPriceExclu02[idIndex] ? productPriceExclu02[idIndex] : 0);

                                if (idIndex == (shippedData?.length - 1)) {
                                    if (totalPriceExcludingTax != 0) {
                                        const pointUsage = Number(item?.totalProductPrice) - Number(item?.totalProductConsumptionTax) - Number(totalPriceExcludingTax) - Number(item?.pointUsageAmount);

                                        if (pointUsage >= 200) {
                                            AmountRefundPoint = Math.floor(pointUsage * 0.005);
                                        } else {
                                            AmountRefundPoint = 0;
                                        }
                                        AmountRefundPoint = productPriceAmount.filter((item: any) => item != null).length > 0 || (checked.filter((item: any) => item == true).length > 0) ? (Number(item?.orderPointsAwarded) - AmountRefundPoint) : 0;
                                    }
                                }

                                return (
                                    <Box key={idIndex} sx={{ mb: 3 }}>
                                        <Box className="refund_box">
                                            <Grid container sx={{ py: 3, ml: 2 }}>
                                                <Grid item sm={2} md={2} lg={2}>
                                                    <Box className="refund_head">
                                                        <img
                                                            src={item?.image}
                                                            alt=""
                                                            className="refund_order_img"
                                                        />
                                                    </Box>
                                                </Grid>
                                                <Grid item sm={7} md={7} lg={7} sx={{ mt: 3 }}>
                                                    <Box className="refund_head text_wrap_data">
                                                        <Typography className="refund_text">
                                                            {item.productName}
                                                        </Typography>
                                                    </Box>
                                                    <Typography className="text_wrap_data">
                                                        {t("oms.cpid")} : {item?.cainzProductCode}
                                                    </Typography>
                                                    <Typography className="text_wrap_data">
                                                        {t("oms.quantity")} : {item?.salesVolume}
                                                    </Typography>

                                                    <FormControl
                                                        size="small"
                                                        fullWidth
                                                        sx={{ maxWidth: "90%", mt: 2 }}
                                                    >
                                                        <InputLabel id="demo-simple-select-helper-label">
                                                            {t("oms.reason_for_refund")}
                                                        </InputLabel>
                                                        {/* Refund Reason  */}
                                                        <Select
                                                            fullWidth
                                                            className="oms-tab-filters"
                                                            labelId="demo-simple-select-helper-label"
                                                            id="demo-simple-select-helper"
                                                            label={t("oms.reason_for_refund")}
                                                            name={"trackingId" + idIndex}
                                                            value={Reason[idIndex]} //{Reason}
                                                            disabled={
                                                                shippedData[0]?.depositStatus == CONSTANTS.DEPOSIT_STATUS_REFUND_PROCESS ? true : false
                                                            }
                                                            onChange={(e) => {
                                                                setReasonId(e, idIndex);
                                                                calculation(
                                                                    idIndex,
                                                                    item,
                                                                    Number(textValue[idIndex]),
                                                                    productPriceAmount[idIndex],
                                                                    Number(e.target.value)
                                                                );
                                                            }}
                                                        >
                                                            {refundReason?.map((item: any, index: number) => {
                                                                return (
                                                                    <MenuItem
                                                                        value={Number(item?.id)}
                                                                        key={index}
                                                                    >
                                                                        {item?.reasonDescription}
                                                                    </MenuItem>
                                                                );
                                                            })}
                                                        </Select>
                                                    </FormControl>
                                                </Grid>
                                                <Grid item sm={3} md={3} lg={3} sx={{ mt: 3 }} className="refund-checkbox">
                                                    <Box>
                                                        <Checkbox
                                                            className="oms_head_checkbox"
                                                            disabled={shippedData[0]?.depositStatus == CONSTANTS.DEPOSIT_STATUS_REFUND_PROCESS ? true : Reason[idIndex] ? false : true}

                                                            value={checked[idIndex]}
                                                            onClick={(e) => handleChecked(e, [item], idIndex)}
                                                        />
                                                        {t("oms.refund_checkbox")}
                                                    </Box>
                                                </Grid>
                                            </Grid>
                                        </Box>

                                        {/* Product amount data starts*/}
                                        <Paper>
                                            <TableContainer sx={{ my: 4 }}>
                                                <Table aria-label="simple table">
                                                    <TableHead>
                                                        <TableRow>
                                                            <TableCell></TableCell>
                                                            <TableCell className="text_right">
                                                                <Box>{t("oms.order_amount")}</Box>
                                                            </TableCell>
                                                            <TableCell className="text_right">
                                                                <Box>
                                                                    {t("oms.prior_refund")}
                                                                </Box>
                                                            </TableCell>
                                                            <TableCell sx={{ textAlign: "center" }}>
                                                                <Box>{t("oms.amount_to_refund")}</Box>
                                                            </TableCell>
                                                            <TableCell>
                                                                <Box>{t("oms.refund_quantity")}</Box>
                                                            </TableCell>
                                                            <TableCell className="text_right">
                                                                <Box>{t("oms.amount")}</Box>
                                                            </TableCell>
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                        {/*===First Row===*/}
                                                        <TableRow>
                                                            <TableCell>

                                                                <Box>{t("oms.product_price")}</Box>
                                                            </TableCell>
                                                            <TableCell className="text_right">
                                                                <Box> &yen; {new Utility().numberWithCommas(item.productPriceSubtotal)}</Box>
                                                            </TableCell>
                                                            <TableCell className="text_right">
                                                                <Box>&yen; {new Utility().numberWithCommas(item.productRefundAmount)}</Box>
                                                            </TableCell>

                                                            <TableCell>
                                                                <Paper
                                                                    component="form"
                                                                    sx={{
                                                                        p: "2px 4px",
                                                                        display: "flex",
                                                                        alignItems: "right",
                                                                    }}
                                                                    className="paper_width"
                                                                >
                                                                    <IconButton
                                                                        sx={{ p: "10px", backgroundColor: "" }}
                                                                        aria-label="menu"
                                                                    >
                                                                        &yen;
                                                                    </IconButton>
                                                                    <Divider
                                                                        sx={{ height: 28, m: 0.5 }}
                                                                        orientation="vertical"
                                                                    />
                                                                    <InputBase
                                                                        sx={{ ml: 1, flex: 1, }}
                                                                        placeholder=""
                                                                        inputProps={{ "aria-label": "Product Price" }}
                                                                        id="input_right"
                                                                        name={"trackingId1" + idIndex}
                                                                        value={checked[idIndex] ? maxProductPriceAmount[idIndex] : productPriceAmount[idIndex] ?? 0}
                                                                        disabled={
                                                                            shippedData[0]?.depositStatus == CONSTANTS.DEPOSIT_STATUS_REFUND_PROCESS ? true : Reason[idIndex] != 0 && textValue[idIndex] ? (checked[idIndex] ? true : false) : true
                                                                        }
                                                                        onKeyDown={handleKeyPress} // Attach the event handler

                                                                        onChange={(e) => {
                                                                            if (/[０-９]/.test(e.target.value)) {
                                                                                setAlertModalOpen(true);
                                                                            } else {
                                                                                setTrackingDataProductPriceAmount(
                                                                                    e,
                                                                                    idIndex
                                                                                );
                                                                                calculation(
                                                                                    idIndex,
                                                                                    item,
                                                                                    Number(textValue[idIndex]),
                                                                                    Number(e.target.value)

                                                                                );
                                                                                Number(item?.productPrice) * Number(textValue[idIndex]) < Number(e.target.value) ? setFlag(false) : setFlag(true);

                                                                            }
                                                                        }

                                                                        }

                                                                    />
                                                                </Paper>
                                                                {Number(item?.productPrice) * Number(checked[idIndex] ? Number(item?.salesVolume) : (Number(textValue[idIndex]))) < Number(checked[idIndex] ? maxProductPriceAmount[idIndex] : productPriceAmount[idIndex]) ? (
                                                                    <Typography className="refund_order_text_color">
                                                                        {t("oms.Amount_can_not_be_exceed_to_the_max_amount")}
                                                                    </Typography>
                                                                ) : null}


                                                            </TableCell>
                                                            <TableCell>
                                                                <FormControl fullWidth >
                                                                    <Select
                                                                        className="cancel_select"
                                                                        sx={{ alignItems: "center" }}
                                                                        fullWidth
                                                                        size="small"
                                                                        id="demo-multiple-name"
                                                                        name={"trackingId2" + idIndex}
                                                                        disabled={
                                                                            shippedData[0]?.depositStatus == CONSTANTS.DEPOSIT_STATUS_REFUND_PROCESS ? true : Reason[idIndex] != 0 ? (checked[idIndex] ? true : false) : true
                                                                        }
                                                                        value={checked[idIndex] ? Number(item?.salesVolume) : (Number(textValue[idIndex]) ? Number(textValue[idIndex]) : 0)}
                                                                        onChange={(event) => {
                                                                            const arrayAmount = [...textValue];

                                                                            arrayAmount[idIndex] = Number(
                                                                                event?.target?.value
                                                                            );

                                                                            setTextValue(arrayAmount);
                                                                            Number(event?.target?.value) > item?.salesVolume ? setFlagQuantity(false) : setFlagQuantity(true);
                                                                            calculation(

                                                                                idIndex,
                                                                                item,
                                                                                Number(event?.target?.value),
                                                                                productPriceAmount[idIndex]
                                                                            );

                                                                            Number(item?.productPrice) * Number(event?.target?.value) < Number(productPriceAmount[idIndex]) || Number(item?.individualShippingFee) * Number(event?.target?.value) < Number(otherConcessionAmount[idIndex]) ? setFlag(false) : setFlag(true);


                                                                        }}
                                                                        input={<OutlinedInput />}
                                                                    >
                                                                        <MenuItem value={0}>0</MenuItem>
                                                                        {setProductQuantityOption(
                                                                            item,
                                                                            idIndex
                                                                        )}
                                                                    </Select>
                                                                </FormControl>

                                                                {textValue[idIndex] > item?.salesVolume ? (
                                                                    <Typography className="refund_order_text_color">
                                                                        {t("oms.maximum_refund_quantity_should_not_exceed_the_sales_quantity")}
                                                                    </Typography>
                                                                ) : null}
                                                            </TableCell>

                                                            <TableCell className="text_right">
                                                                {t("oms.max")} : &yen; {checked[idIndex] ? maxProductPriceAmount[idIndex] : totalPointValue ?? 0}
                                                            </TableCell>
                                                        </TableRow>
                                                        {/*===First Row===*/}

                                                        {/*===Second Row===*/}
                                                        <TableRow>
                                                            <TableCell>

                                                                <Typography>
                                                                    {t("oms.other_concession")}
                                                                </Typography>
                                                            </TableCell>
                                                            <TableCell className="text_right">
                                                                <Box> &yen; {new Utility().numberWithCommas(item?.individualShippingFee)} </Box>
                                                            </TableCell>
                                                            <TableCell className="text_right">

                                                                <Box> &yen; {new Utility().numberWithCommas(item?.optionalRefundAmount)}</Box>
                                                            </TableCell>
                                                            <TableCell>
                                                                <Paper
                                                                    component="form"
                                                                    sx={{
                                                                        p: "2px 4px",
                                                                        display: "flex",
                                                                        alignItems: "center",
                                                                    }}
                                                                    className="paper_width"
                                                                >
                                                                    <IconButton
                                                                        sx={{ p: "10px", backgroundColor: "" }}
                                                                        aria-label="menu"
                                                                    >
                                                                        &yen;
                                                                    </IconButton>
                                                                    <Divider
                                                                        sx={{ height: 28, m: 0.5 }}
                                                                        orientation="vertical"
                                                                    />
                                                                    <InputBase
                                                                        sx={{ ml: 1, flex: 1 }}
                                                                        id="input_right"
                                                                        placeholder=""
                                                                        inputProps={{ "aria-label": "" }}
                                                                        name={"trackingId3" + idIndex}
                                                                        onKeyDown={handleKeyPress} // Attach the event handler
                                                                        value={checked[idIndex] ? maxOtherConcessionAmount[idIndex] : otherConcessionAmount[idIndex] ?? 0}
                                                                        disabled={
                                                                            shippedData[0]?.depositStatus == CONSTANTS.DEPOSIT_STATUS_REFUND_PROCESS ? true : Reason[idIndex] != 0 && textValue[idIndex] ? (checked[idIndex] ? true : false) : true
                                                                        }
                                                                        onChange={(e) => {
                                                                            if (/[０-９]/.test(e.target.value)) {
                                                                                setAlertModalOpen(true);
                                                                            } else {
                                                                                setOtherconcessionAmountFunctio(e, idIndex);
                                                                                Number(item?.individualShippingFee) * Number(textValue[idIndex]) < Number(e.target.value) ? setFlag(false) : setFlag(true);
                                                                            }
                                                                        }


                                                                        }

                                                                    />
                                                                </Paper>
                                                                {Number(item?.individualShippingFee) <
                                                                    Number(otherConcessionAmount[idIndex]) ? (
                                                                        <Typography className="refund_order_text_color">
                                                                            {t("oms.Amount_can_not_be_exceed_to_the_max_amount")}
                                                                        </Typography>
                                                                    ) : null}
                                                            </TableCell>
                                                            <TableCell></TableCell>
                                                            <TableCell className="text_right">
                                                                {t("oms.max")} : &yen;
                                                                {concessionMax ?? 0}
                                                            </TableCell>
                                                            {/*  calculation should be done in variable */}
                                                        </TableRow>
                                                        {/*===Second Row===*/}
                                                        {/*===Third Row===*/}
                                                        <TableRow>
                                                            <TableCell>

                                                                <Typography>{t("oms.total")}</Typography>
                                                            </TableCell>
                                                            <TableCell className="text_right">
                                                                <Box>  &yen; {new Utility().numberWithCommas(item?.totalProductOrderAmount)}</Box>
                                                            </TableCell>
                                                            <TableCell className="text_right">
                                                                <Box> &yen; {new Utility().numberWithCommas(item?.productRefundSubtotal)}</Box>
                                                            </TableCell>
                                                            <TableCell className="text_right">
                                                                <Typography className="text-danger" sx={{ width: "210px" }}>
                                                                    {checked[idIndex] ? new Utility().numberWithCommas(maxAmountValue?.toString()) : new Utility().numberWithCommas(amountValue?.toString())}
                                                                </Typography>
                                                            </TableCell>
                                                            <TableCell></TableCell>

                                                        </TableRow>
                                                        {/*===Third Row===*/}
                                                    </TableBody>
                                                </Table>
                                            </TableContainer>
                                        </Paper>
                                    </Box>
                                );
                            })
                        )}
                    </Grid>

                    <Grid
                        item
                        xl={2}
                        lg={3}
                        md={4}
                        sm={12}
                        xs={12}
                    >
                        <Grid
                            container
                            sx={{ p: 2 }}
                            className="refund_product_head refunds_head_border"
                        >
                            <Grid item lg={12}>
                                <Typography>{t("oms.order_summary")}</Typography>
                            </Grid>
                        </Grid>
                        <Grid
                            container
                            sx={{ py: 3, pl: 2 }}
                            className="refunds_product_head"
                        >
                            <Grid item xs={12} sm={6} md={6} lg={6}>
                                <Typography>{t("oms.order_id")} : </Typography>
                            </Grid>
                            <Grid item xs={12} sm={6} md={6} lg={6}>
                                <Typography>{orderID}</Typography>
                            </Grid>
                        </Grid>
                        <Grid sx={{ pt: 3 }}>
                            <Grid
                                container
                                sx={{ p: 2 }}
                                className="refund_product_head refunds_head_border"
                            >
                                <Grid item lg={12}>
                                    <Typography>{t("oms.refund_summary")}</Typography>
                                </Grid>
                            </Grid>
                            <Grid
                                container
                                sx={{ py: 3, pl: 2, p: 1 }}
                                className="refunds_product_head"
                            >
                                <Grid item xs={12} sm={6} md={6} lg={8}>
                                    <Typography>{t("oms.amount_to_refunds")} : </Typography>
                                </Grid>
                                <Grid item xs={12} sm={6} md={6} lg={4} className="text-danger word_wrap">
                                    <Typography className="text_right">&yen;{new Utility().numberWithCommas(totalAmount?.toString())}</Typography>
                                    {/** totalAmount should be here */}
                                </Grid>
                                <Grid item xs={12} sm={6} md={6} lg={8}>
                                    <Typography>{t("oms.prior_refunds")} : </Typography>
                                </Grid>
                                <Grid item xs={12} sm={6} md={6} lg={4} className="word_wrap" >
                                    <Typography className="text_right">&yen;{new Utility().numberWithCommas(totalPriorAmount?.toString())}</Typography>
                                </Grid>
                                <Grid item xs={12} sm={6} md={6} lg={8}>
                                    <Typography>{t("oms.points_returned")} : </Typography>
                                </Grid>
                                <Grid item xs={12} sm={6} md={6} lg={4} className="word_wrap">
                                    <Typography className="text_right">&yen;{ memberFlag == 1 || memberFlag == 2 ? new Utility().numberWithCommas(Math.round(AmountRefundPoint)?.toString()) : 0}</Typography>
                                    {/**Spelling of name should be considered */}
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid container sx={{ pt: 3 }}>
                            <Grid item xs={6} sm={6} md={6} lg={6}>
                                <Button variant="outlined" className="refund_cancel_botton" onClick={handleRefund}>
                                    {t("oms.cancel")}
                                </Button>
                            </Grid>
                            <Grid item xs={6} sm={6} md={6} lg={6} className="refund_botton">
                                <CommonButton route={window.location.pathname} functionToExecute={onSumbit} className="unshipped refund_submit_botton product_list_download" btnName={t("oms.submit_refund")} disableCondition={shippedData[0]?.depositStatus == CONSTANTS.DEPOSIT_STATUS_REFUND_PROCESS || val ? true : textValue.filter((item: any) => item ? item != 0 : false).length > 0 && productPriceAmount.filter((item: any) => item != 0).length > 0 && flag && flagQuantity ? false : true} />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <ModalUtility msg={t("registerpage.single_byte_numbers")} modalOpen={alertModalOpen} setModalOpen={setAlertModalOpen} />
            </Container>
        </>
    );
};

export default RefundOrder;

