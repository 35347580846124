import React, { useEffect, useState} from "react";
import { Container, Box, Grid } from "@mui/material";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import HeaderTitle from "../common/HeaderTitle";
import { useTranslation } from "react-i18next";
import OmsUnshipped from "./OmsUnshipped";
import Omsshipped from "./OmsShipped";
import Cancelled from "./Cancelled";
import PreparingForShipment from "./PreparingForShipment";
import RefundedOrders from "./RefundedOrders";
import { commonActions } from "../../redux/features/common/commonSlice";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import OmsDownload from "./OmsDownload";


const Oms = (props: any) => {
    const { showSnackbar, handleLoader } = props;
    const firstPage = 1;
    const [t, i18n] = useTranslation();
    const [value, setValue] = useState<string>("1");
    // const [values, setValues] = useState();
    const [backOrderedCount, setBackOrderedCount] = useState();
    const [preparingShipmentCount, setPreparingShipmentCount] = useState();
    
    // let [panel1,panel2,panel3,panel4] = ["1","2","3","4"];
    
    const dispatch = useAppDispatch();
    const page = useAppSelector( (state) => state?.common?.isTabUrl);
    const handleChange = (event: React.SyntheticEvent, newValue: string) => {
        setValue(newValue);
        dispatch(commonActions.IS_TAB_URL(newValue));
    };

    useEffect(()=>{
        if(page !== undefined){
            setValue(page);
        }
    },[page]);

    return (
        <>
            <Container sx={{ py: 2 }}>
                <HeaderTitle title={t("oms.manage_order")} />
                <Box
                    sx={{
                        maxWidth: { xs: "600px", sm: "100%", md: "100%", lg: "100%" },
                    }}
                >
                    <TabContext value={value}>
                        {/*Tab menu starts here*/}
                        <Container sx={{ py: 1 }}>
                            <Grid container spacing={0}>
                                <Grid item xs={12} sm={12} md={12} lg={12}>
                                    <Box>
                                        <TabList
                                            onChange={handleChange}
                                            variant="scrollable"
                                            scrollButtons="auto"
                                            aria-label="scrollable auto tabs example"
                                        >
                                            {/* <Tab
                                                iconPosition="start"
                                                label={"0   " + t("oms.pending")}
                                                value={panel2 ?? "2"}
                                                color="primary"
                                                sx={{
                                                    textTransform: "capitalize",
                                                    borderBottom: 1,
                                                    borderColor: "divider",
                                                    mr: 2,
                                                }}
                                            /> */}
                                            <Tab
                                                iconPosition="start"
                                                label= {(backOrderedCount ? backOrderedCount : 0)  + "  " + t("oms.unshipped")}
                                                // value={ values != ROUTES.OMS ? "4" : "1"}
                                                // value={panel1 ?? "1"}
                                                value="1"
                                                className="oms_tab"
                                            />
                                            <Tab
                                                iconPosition="start"
                                                label={(preparingShipmentCount ? preparingShipmentCount : 0)  + " " + t("oms.preparing_for_shipment")}
                                                // value={panel2 ?? "2"}
                                                value="2"
                                                className="oms_tab"
                                            />
                                            <Tab
                                                iconPosition="start"
                                                label={"  " + t("oms.shipped")}
                                                // value={panel3 ?? "3"}
                                                value="3"
                                                className="oms_tab"
                                            />
                                            <Tab
                                                iconPosition="start"
                                                label={" " + t("oms.cancelled")}
                                                // value={panel4 ?? "4"}
                                                value="4"
                                                className="oms_tab"
                                            />
                                            <Tab
                                                iconPosition="start"
                                                label={"" + t("oms.download")}
                                                // value={panel4 ?? "4"}
                                                value="5"
                                                className="oms_tab"
                                            />
                                            <Tab
                                                iconPosition="start"
                                                label={"" + t("oms.refunded_orders")}
                                                // value={panel4 ?? "4"}
                                                value="6"
                                                className="oms_tab"
                                            />
                                        </TabList>
                                    </Box>
                                </Grid>
                            </Grid>
                        </Container>
                        {/*Tab menu ends here*/}
                        <TabPanel value="1">
                            <OmsUnshipped
                                handleLoader={handleLoader}
                                showSnackbar={showSnackbar}
                                setBackOrderedCount={setBackOrderedCount}
                                setPreparingShipmentCount={setPreparingShipmentCount}
                            />
                        </TabPanel>
                        {/* <TabPanel value={panel2 ?? "2"} >
                            <Container> Pending Page</Container>
                        </TabPanel> */}
                        <TabPanel value="2">
                            <Container>
                                <PreparingForShipment handleLoader={handleLoader} showSnackbar={showSnackbar} setBackOrderedCount={setBackOrderedCount}
                                    setPreparingShipmentCount={setPreparingShipmentCount}/>
                            </Container>
                        </TabPanel>
                        <TabPanel value="3">
                            <Container>
                                <Omsshipped handleLoader={handleLoader} showSnackbar={showSnackbar} setBackOrderedCount={setBackOrderedCount}
                                    setPreparingShipmentCount={setPreparingShipmentCount}/>
                            </Container>
                        </TabPanel>
                        <TabPanel value="4">
                            <Container>
                                <Cancelled handleLoader={handleLoader} showSnackbar={showSnackbar} setBackOrderedCount={setBackOrderedCount}
                                    setPreparingShipmentCount={setPreparingShipmentCount}/>
                            </Container>
                        </TabPanel>
                        <TabPanel value="5">
                            <Container>
                                <OmsDownload handleLoader={handleLoader} showSnackbar={showSnackbar} setBackOrderedCount={setBackOrderedCount}
                                    setPreparingShipmentCount={setPreparingShipmentCount}/>
                            </Container>
                        </TabPanel>
                        <TabPanel value="6">
                            <Container>
                                <RefundedOrders handleLoader={handleLoader} showSnackbar={showSnackbar} setBackOrderedCount={setBackOrderedCount}
                                    setPreparingShipmentCount={setPreparingShipmentCount}/>
                            </Container>
                        </TabPanel>
                    </TabContext>
                </Box>
            </Container>
        </>
    );
};

export default Oms;
