import React, { useEffect, useRef, useState } from "react";
import {
    Button,
    Container,
    Typography,
    Box,
    TextField,
    Grid,
    InputLabel,
} from "@mui/material";
// import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Checkbox from "@mui/material/Checkbox";
import ImageIcon from "@mui/icons-material/Image";
import { useTranslation } from "react-i18next";
import HeaderTitle from "../../common/HeaderTitle";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import confirmShipmentService from "../service/confirmShipmentService";
import ShipmentDataService from "../service/shipmentDataService";
import SimpleReactValidator from "simple-react-validator";
import CONSTANTS, { LIST_LIMIT } from "../../../constants/constants";
import Skeleton from "../../common/Skeleton";
import Pagination from "../../common/Pagination";
import { ROUTES } from "../../../constants/routes";
import { useNavigate } from "react-router";
import { commonActions } from "../../../redux/features/common/commonSlice";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import OmsManageOrderService from "../service/OmsManageOrderService";
import { AnyAaaaRecord } from "dns";
import CommonButton from "../../common/Button";
// import Pagination from "../common/Pagination";

const ShippingMultipleOrder = (props: any) => {
    // const { showSnackbar, handleLoader } = props;
    const [t, i18n] = useTranslation();
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    // const [skeleton, setSkeleton] = useState(true);
    // const [shipdate, setShipDate] = React.useState<string | number>("");
    // const [shipfrom, setShipfrom] = React.useState<string | number>("");
    // const [smoData, setSmoData] = useState<any>([]);
    const [shipmentsData, setShipmentsData] = useState<any>([]);
    const [skeleton, setSkeleton] = useState(false);
    // const [currentPage, setCurrentPage] = useState<any>(1);
    // const [totalCount, setTotalCount] = useState<any>(0);
    // const [totalPages, setTotalPages] = useState<any>(0);
    const { showSnackbar, handleLoader } = props;
    const [open, setOpen] = React.useState(false);
    const [dateRang, setDateRang] = React.useState("");
    const [resultpage, setResultPage] = useState("");
    const [shippingservice, setShippingsService] = useState("");
    const [others, setOthers] = useState<any>("");
    const [trackingId, setTrackingId] = useState<any>([]);
    const [invoiceNoArr,setInvoiceNoArr]=useState<any>([]);
    const [limit, setLimit] = useState(LIST_LIMIT.OMS_MULTIPLE_ORDER_LIST_LIMIT);
    const [checked, setChecked] = useState(new Array(limit).fill(false));
    const orderIdCompare: any[] = [];
    const invoiceNumbersArray:any = [];  
    const orderId = useAppSelector((state) => state.common.isOrderIdStored) ?? null;
    const orderData = useAppSelector((state) => state.common.isOrderDataStored) ?? null;

    // const handleCheckAllChange = (e:any) => {
    //     e.target.checked == true  
    //         ? setChecked(new Array(i).fill(true))
    //         : setChecked(new Array(i).fill(false));
    // };

    const handleCheckboxChange = (index: any) => (event: any) => {
        const newChecked = [...checked];
        newChecked[index] = event.target.checked;
        setChecked(newChecked);
    };


    // validation
    const [, forceUpdate] = useState(0);

    // eslint-disable-next-line no-control-regex
    const isSingleByte = (value: string) => { return /^[\x00-\xFF]*$/.test(value); };

    const isPipeSymbol = (value : string) => {
        return CONSTANTS.TRACKING_ID_REGEX.test(value); 
    };

    const validator = useRef(
        new SimpleReactValidator({
            autoForceUpdate: { forceUpdate },
            className: "text-danger",
            messages: {
                required: t("required.this_field_cannot_be_empty"),
                regex: t("oms.please_enter_half_width_alphanumeric_characters"),
            },
            validators: {
                maxChar100Validation: {
                    message: t("registerpage.max_char",{size:100}).toString(),
                    rule: (value: string, params: number) => {
                        return value.length <= params;
                    },
                },
                maxChar50Validation: {
                    message: t("registerpage.max_char",{size:50}).toString(),
                    rule: (value: string, params: number) => {
                        return value.length <= params;
                    },
                },
                SingleByte: {
                    message: t("registerpage.single_byte_characters"),
                    rule: (value) => isSingleByte(value),
                },
                unique: {
                    message: t("oms.tracking_id_unique_msg").toString(),
                    rule: (value: any, params: any) => {
                        return !params[0];
                    }
                },
                duplicate: {
                    message: t("oms.tracking_id_unique_msg").toString(),
                    rule: (value: any, params: any) => {
                        return !params[0];
                    }
                },
                pipeSymbol : {
                    message : t("oms.pipe_hyphen_symbols_cannot_be_entered"),
                    rule : (value) => isPipeSymbol(value)
                }
            }
        })
    );

    useEffect(() => {
        forceUpdate(1);
    });

    useEffect(() => {
        window.scrollTo(0, 0);
        invoiveNoReceived();      
    }, []);

    const invoiveNoReceived = () =>{
        for (const order of orderData) {
            if (orderId?.includes(order?.orderId)) {
                for (const product of order.products) {
                    if (product?.invoiceNumber) {
                        const splitInvoiceNumbers = product?.invoiceNumber.split("|");
                        invoiceNumbersArray?.push(...splitInvoiceNumbers);
                    }
                }
            }
        }
        setInvoiceNoArr(invoiceNumbersArray);
    };

    const handleOnBlur = (e: any) => {
        const { name } = e.target;
        validator.current.showMessageFor(name);

    };

    
    // Api cal for confirmShipmentList


    
    const handleSelectRange = (event: SelectChangeEvent) => {
        setDateRang(event.target.value);
    };

    const handleResultPage = (e: any) => {
        setResultPage(e.target.value);
    };

    const handleOtherCarrier = (e: any) => {
        setOthers(e.target.value);
    };

    // Api cal for confirm shipment
    const shipmentData = (shipmentDates: any, carrier: any) => {
        const responseArr: any = [];
        orderIdCompare.map((orderId: any, index: number) => {
            orderData?.filter((orderItem:any)=>orderItem.orderId==orderId).map((order: any) => {
                order?.products?.map((products: any, count: number) => {
                    const shippedVolume = (products.shippedQuantity.split("|")).reduce(myFunc);
                    function myFunc(total: any, num: any) {
                        return parseInt(total) + parseInt(num);
                    }
                    const shippedQuantity = (Number(products?.salesVolume) - shippedVolume);
                    // if(shippedQuantity != 0 ) {
                    responseArr.push({
                        orderId: order?.orderId,
                        productCode: products?.cainzProductCode,
                        shipmentDate: shipmentDates,
                        carrier: carrier?.trim(), 
                        shippedQuantity: shippedQuantity,
                        trackingId: trackingId[index]
                    });
                    // }
                });
            });
        });
        if(validator.current.allValid()){
            handleLoader(true);
            const payload = responseArr;
            const isHitDummyURL = false;
            new ShipmentDataService(payload, isHitDummyURL)
                .confirmShipmentData()
                .then((res) => {
                    handleLoader(false);
                    showSnackbar(res, true);
                    setShipmentsData(res);
                    handleOrderList();
                })
                .catch((err: any) => {
                    handleLoader(false);
                    showSnackbar(err?.message, false);
                });
        } else {
            validator.current.showMessages();
        }
    };

    // function use print packing slip

    const handleAllSlip = (checkList: any) => {
        const orderId2: any = [];

        for (let index = 0; index < checkList.length; index++) {
            if (checkList[index]) {
                orderId2.push(orderIdCompare[index]);
            }
        }
        const orderId = orderId2.filter((item: any) => item != undefined);

        if (checked.filter((e) => e == true).length > 0) {
            dispatch(commonActions.SAVE_ORDER_ID(orderId2));
            window.open(ROUTES.PRINT_PACKING_SLIP, "_blank", "noreferrer");
        } else {
            alert("Please Select at least one order");
        }
    };

    // ***** Save all information as state array  ***** //
    const handleChanges = (e: any, itemProduct: number) => {
        const { name, value } = e.target;
        let inputValue: any = [];

        if (name == "tracking-id" + itemProduct) {
            inputValue = [...trackingId];
            inputValue[itemProduct] = value.toString();
            setTrackingId(inputValue);
        }
        validator.current.hideMessageFor(name);
    };

    //************Ship Date Dropdown function*****************
    const currentDate = new Date();
    const startDate = (orderData[orderData.length-1]?.orderDate);
    const dateOptions: any = [];
    let formattedCurrentDate: any;
    
    const generateDates = () => {
        const date = new Date(startDate.split(" ")[0]);

        while (date <= currentDate) {
            const formattedDate = date.toLocaleDateString("en-ZA", {
                year: "numeric",
                month: "numeric",
                day: "numeric",
            });

            dateOptions.push(formattedDate);
            date.setDate(date.getDate() + 1);
        }
        formattedCurrentDate = currentDate.toLocaleDateString("en-ZA", {
            year: "numeric",
            month: "numeric",
            day: "numeric",
        });
    };
    const handleOrderList = () => {
        navigate(ROUTES.OMS);
    };

    generateDates();

    const checkTrackingId = (indexProduct: number) => {
        const trackingIdResult =  trackingId.map((item: any, index: number) => {
            if(index == indexProduct){
                return false;
            }
            if(item == trackingId[indexProduct]){
                return true;
            } else {
                return false;
            }
        });
        return trackingIdResult.filter((item: any) => item == true).length > 0 ?  true : false;
    };

    const checkDuplicateId = (indexProduct: number) => {
        if(invoiceNoArr.includes(`${resultpage== "その他" ? others?.trim() :resultpage}-${trackingId[indexProduct]}`)){
            return true;
        }else{
            return false;
        }
    };

    return (
        <>
            <HeaderTitle
                title={t("shipping-multiple-orders.shipping_multiple_order")}
            />
            <Container className="oms_table">
                <Grid container spacing={2} >

                    <Grid item xs={6} sm={6} md={6} lg={6}>
                        <Box
                            sx={{ my: 2 }}
                           
                        >
                

                            <Button
                                onClick={handleOrderList}
                                className="oms_head_btn"
                                variant="contained"
                            >
                                {t("oms.back_to_order_list")}
                            </Button>
                        </Box>

                    </Grid>
                    <Grid item xs={6} sm={6} md={6} lg={6}>
                        <Box
                            sx={{ my: 2 }}
                            display="flex"
                            justifyContent="flex-end"
                            alignItems="flex-end"
                        >
                            <CommonButton route={window.location.pathname} functionToExecute={()=>shipmentData(dateRang ? dateRang : formattedCurrentDate, resultpage == "その他" ? others?.trim() : resultpage)} className="oms_head_btn" btnName={t("oms.ship")} disableCondition={false}/>
                            {/* <Button
                                onClick={()=>shipmentData(dateRang ? dateRang : formattedCurrentDate, resultpage == "その他" ? others : resultpage)}
                                className="oms_head_btn"
                                variant="contained"
                            >
                                {t("oms.ship")}
                            </Button> */}
                        </Box>

                    </Grid>
                </Grid>

                <Box className="confirm_shipment_bg">
                    <Box sx={{ my: 2 }} className="border-size confirm_shipment">
                        <Grid item xs={12} sm={12} md={12} lg={12}>
                            <Grid className="ship-border" sx={{mb:2}}>
                                <Typography variant="h6"> {t("oms.confirm_shipment")}</Typography>
                            </Grid>
                            <Grid container spacing={2} >
                                <Grid item lg={2} md={5} sm={5} xs={12}>
                                    <Box className="select_height">
                                        <FormControl size="small" fullWidth>
                                            <InputLabel id="demo-simple-select-helper-label">
                                                {t("oms.ship_date")}                                       
                                            </InputLabel>
                                            <Select fullWidth 
                                                className="oms-tab-filter"
                                                labelId="demo-simple-select-helper-label"
                                                id="demo-simple-select-helper"
                                                value={dateRang ? dateRang : formattedCurrentDate}
                                                label={t("oms.ship_date")}
                                                onChange={handleSelectRange}
                                                MenuProps={{
                                                    style: {
                                                        maxHeight: 400,
                                                    },
                                                }}
                                           
                                            >
                                                {dateOptions.map((date: any) => {
                                                    return (
                                                        <MenuItem key={date} value={date}>
                                                            {date}
                                                        </MenuItem>
                                                    );
                                                })}
                                            </Select>
                                            {validator.current.message("dateRang", dateRang ? dateRang : formattedCurrentDate, ["required"])}
                                        </FormControl>
                                    </Box>
                                </Grid>
                                <Grid item lg={2} md={5} sm={5} xs={12}>
                                    <Box>
                                        <FormControl size="small" fullWidth>
                                            <InputLabel id="demo-simple-select-helper-label">
                                                {t("oms.carrier")}
                                            </InputLabel>
                                            <Select fullWidth
                                                className="oms-tab-filters"
                                                labelId="demo-simple-select-helper-label"
                                                id="demo-simple-select-helper"
                                                value={resultpage ?? ""}
                                                label={t("oms.carrier")}
                                                onChange={(e) => handleResultPage(e)}
                                            >
                                                {CONSTANTS.CARRIER_MENU_OMS.map((name) => (
                                                    <MenuItem key={name} value={name}>
                                                        {name}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                            {validator.current.message("resultpage", resultpage, ["required"])}
                                        </FormControl>
                                    </Box>
                                </Grid>
                                {
                                    resultpage== "その他" && (
                                        <Grid item lg={2} md={5} sm={5} xs={12} sx={{ width: "100%", mr: 2 }}>
                                            <TextField
                                                className="multiple-shipment-others-input-field-label multiple-shipment-others-input-field"
                                                name="others"
                                                label={t("oms.others")}
                                                value={others ?? ""}
                                                onChange={(e) => handleOtherCarrier(e)}
                                                sx={{ background: "#fff", width: "100%" }}
                                               
                                            ></TextField>
                                            {validator.current.message(
                                                "others",
                                                others?.trim(),
                                                ["required", { maxChar100Validation: 100 }, "pipeSymbol"]
                                            )}
                                        </Grid>
                                    )
                                }
                            </Grid>
                        </Grid>
                    </Box>
                </Box>
            </Container>
            <Container className="oms_table">
                {skeleton?<Skeleton
                    varient="rectangular"
                    width={0}
                    height={60}
                    count="10"/> :
                    
                    <TableContainer component={Paper}>
                
                        <Table aria-label="simple table">
                            <TableHead className="oms_head">
                                <TableRow>
                                    <TableCell>
                                        <Box>
                                            {t("oms.order_date")}
                                        </Box>
                                    </TableCell>
                                    <TableCell>
                                        <Box>{t("oms.order_details")}</Box>
                                    </TableCell>
                                    
                                    <TableCell >
                                        <Box>{t("oms.product_information")} </Box>
                                    </TableCell>
                                    <TableCell>
                                        <Box>{t("oms.customer_option")}</Box>
                                    </TableCell>
                                    
                                    <TableCell style={{ width: "100px" }}>
                                        <Box>{t("oms.tracking_id")}</Box>
                                    </TableCell>
                                </TableRow>
                            </TableHead>

                            {
                                orderId?.map((order_id: string , orderIndex: number) => (
                                    <TableBody key={orderIndex}>
                                    
                                        {
                                            orderData?.filter((orderItem:any)=>orderItem.orderId==order_id).map((store:any, index: number)=>{
                                                const products = store?.products;
                                                orderIdCompare.push(store.orderId);
                                                return(
                                                    <TableRow key={index}>
                                                        <TableCell>
                                                            <Typography
                                                                className={"order_date_checkbox " + index}
                                                            >
                                                                {store.orderDate}
                                                            </Typography>
                                                        </TableCell>
                                                        <TableCell  colSpan={5} >
                                                            <Table>
                                                                <TableBody>
                                                                    <TableRow className="CEC-border-none">
                                                                        <TableCell style={{ width: "26%" }}>
                                                                            <Typography>
                                                                                {t("oms.order_id")} : 
                                                                            </Typography>
                                                                            <Typography className="text_wrap_data">
                                                                                {store.orderId}
                                                                            </Typography>
                                                                            <Typography>
                                                                                {t("oms.buyer_name")} : 
                                                                            </Typography>
                                                                            <Typography className="text_wrap_data">
                                                                                {store?.ordererName} {store?.nameOfOrderer}
                                                                            </Typography>
                                                                        </TableCell>
                                                                        <TableCell
                                                                            colSpan={4}
                                                                        >
                                                                            <Table>
                                                                                <TableBody>
                                                                                    {products?.map(
                                                                                        (store: any,productIndex:any) => (
                                                                                            <TableRow
                                                                                                key={productIndex}
                                                                                            >
                                                                                                <TableCell className="table_cell_padding">
                                                                                                    <img
                                                                                                        src={
                                                                                                            store?.image
                                                                                                        }
                                                                                                        alt=""
                                                                                                    />
                                                                                                    <Typography className="text_wrap_data">
                                                                                                        {
                                                                                                            store?.productName
                                                                                                        }
                                                                                                    </Typography>

                                                                                                    {/* <Typography>
                                                                                                        {store?.productName}
                                                                                                    </Typography> */}

                                                                                                    <Typography>
                                                                                                        {t("oms.cpid")} : {store?.cainzProductCode}
                                                                                                    </Typography>
                                                                                                   
                                                                                                    <Typography>
                                                                                                        {t("oms.quantity")} : {store?.salesVolume}
                                                                                                    </Typography>
                                                                                                    

                                                                                                    <Typography>
                                                                                                        {t("oms.shipped_quantity")} : {store?.shippedQuantity.split("|").filter((item: any) => item !== "0").length > 0 ? store?.shippedQuantity.split("|").filter((item: any) => item !== "0").join(",") : "0"}
                                                                                                    </Typography>
                                                                                                   
                                                                                                    <Typography>
                                                                                                        {t("oms.price_subtotal")} : &yen; {
                                                                                                            store?.productPriceIncludingTaxSubtotal
                                                                                                        }
                                                                                                    </Typography>
                                                                                                   
                                                                                                </TableCell>

                                                                                                <TableCell>
                                                                                                   
                                                                                                    <Typography>
                                                                                                        {t("oms.delivery_by_date")} : 
                                                                                                    </Typography>
                                                                                                    <Typography className="text_wrap_data">
                                                                                                        {store?.productDeliveryDate}
                                                                                                    </Typography>
                                                                                                </TableCell>
                                                                                            </TableRow>
                                                                                        )
                                                                                    )}
                                                                                </TableBody>
                                                                            </Table>
                                                                        </TableCell>       
                                                                        <TableCell >
                                                                            <TextField
                                                                                className="multiple-shipment-others-input-field-label multiple-shipment-others-input-field"
                                                                                name={"tracking-id" + orderIndex}
                                                                                value={trackingId[orderIndex] ?? ""}
                                                                                onChange={(e) => handleChanges(e, orderIndex)}
                                                                                label={t("oms.tracking_id")}
                                                                                sx={{ background: "#fff", mt: 1, width: "100%" }}
                                                                               
                                                                            ></TextField>
                                                                            {validator.current.message(
                                                                                "tracking-id" + orderIndex,
                                                                                trackingId[orderIndex],
                                                                                ["required", { maxChar50Validation: 50 },{ regex: CONSTANTS.TRACKING_REGEX },{unique: checkTrackingId(orderIndex)},{duplicate: checkDuplicateId(orderIndex)},"SingleByte"]
                                                                            )}
                                                                        </TableCell>
                                                                    </TableRow>
                                                                </TableBody>
                                                            </Table>
                                                        </TableCell>
                                                    </TableRow>
                                                );
                                            }
                                            ) 
                                        }
                                    </TableBody>
                                ))
                            }
                        </Table>
                    </TableContainer>
                }
            </Container>
        </>
    );
};

export default ShippingMultipleOrder;
