import { API } from "../../../config";
import AxiosUtils from "../../../utils/AxiosUtils";
import { serverAPIPath, devServerAPIPath } from "../config/apiPath";
import { LIST_LIMIT } from "../../../constants/constants";
import Cookies from "../../../utils/Cookies";
import { InventoryWithPaginationType } from "../../../interfaces/inventoryInterface";

class ManageInventoryList {
    private payload: object;
    private isHitDummyURL: boolean;
    private headers: object;

    constructor(payload = {}, isHitDummyURL = false) {
        this.payload = payload;
        this.isHitDummyURL = isHitDummyURL;
        this.headers = {
            client_id: process.env.REACT_APP_CAINZ_CLIENT_ID,
            client_secret: process.env.REACT_APP_CAINZ_CLIENT_SECRET,
            "access-token": new Cookies("access-token").read(),
        };
    }

    async inventoryListData(
        page: number,
        limit = LIST_LIMIT.MANAGE_INVENTORY_LIST_LIMIT,
        merchantCode?: number,
        search?: string,
        flag?: any,
        isProductRegistered?: any,
        lessThan?: string,
        colName?:string,
        order?:string
    ) {
        const response = search
            ? await new AxiosUtils(
                `${API.BASE_URL}${devServerAPIPath.MANAGE_INVENTORY_LIST}?merchant=${merchantCode}&page=${page}&limit=${limit}&search=${search}&flag=${flag}&le=${lessThan}&column=${colName}&order=${order ? order : ""}&isProductRegistered=${isProductRegistered}`,
                this.payload,
                this.headers
            ).get()
            : await new AxiosUtils(
                `${API.BASE_URL}${devServerAPIPath.MANAGE_INVENTORY_LIST}?merchant=${merchantCode}&page=${page}&limit=${limit}&flag=${flag}&le=${lessThan}&column=${colName}&order=${ order ? order : "" }&isProductRegistered=${isProductRegistered}`,
                this.payload,
                this.headers
            ).get();

        if (response?.data?.success) {
            const { data, currentPage, totalPages, currentTotalCount, totalCount } =
        response.data.data;
            const inventoryData: InventoryWithPaginationType = {
                inventories: data,
                pagination: {
                    currentPage,
                    totalPages,
                    currentTotalCount,
                    totalCount,
                },
            };
            return inventoryData;
        } else if (response?.data?.success == false) {
            throw new Error(response?.message);
        } else {
            throw new Error(response);
        }
    }

    async downloadInventoryListData(
        merchantCode?: number,
        search?: string,
        flag?: any,
        isProductRegistered?: any,
        lessThan?: string,
        colName?:string,
        order?:string
    ) {
        const response = search
            ? await new AxiosUtils(
                `${API.BASE_URL}${devServerAPIPath.MANAGE_INVENTORY_LIST}?merchant=${merchantCode}&search=${search}&flag=${flag}&le=${lessThan}&download=true&column=${colName}&order=${order ? order : ""}&isProductRegistered=${isProductRegistered}`,
                this.payload,
                this.headers
            ).get()
            : await new AxiosUtils(
                `${API.BASE_URL}${devServerAPIPath.MANAGE_INVENTORY_LIST}?merchant=${merchantCode}&flag=${flag}&le=${lessThan}&download=true&column=${colName}&order=${order ? order : ""}&isProductRegistered=${isProductRegistered}`,
                this.payload,
                this.headers
            ).get();

        if (response?.data?.success) {
            const { data, currentPage, totalPages, currentTotalCount, totalCount } =
        response.data.data;
            const inventoryData: InventoryWithPaginationType = {
                inventories: data,
                pagination: {
                    currentPage,
                    totalPages,
                    currentTotalCount,
                    totalCount,
                },
            };
            return inventoryData;
        } else if (response?.data?.success == false) {
            throw new Error(response?.message);
        } else {
            throw new Error(response);
        }
    }
}

export default ManageInventoryList;
