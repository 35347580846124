import { API } from "../../../config";
import AxiosUtils from "../../../utils/AxiosUtils";
import { mockAPIPath, serverAPIPath } from "../config/apiPath";
import Cookies from "../../../utils/Cookies";
import { LIST_LIMIT } from "../../../constants/constants";
import OMSType, { OMSTypeWithPaginationType } from "../../../interfaces/manageOrderInterface";
import confirmShipmentType from "../../../interfaces/confirmOrderShipmentInterface";
import { RefundedOrderInterfaceWithPaginationType } from "../../../interfaces/refundedOrderInterface";

class OmsManageOrderService {
    private payload: object;
    private isHitDummyURL: boolean;
    private headers: object;

    constructor(payload = {}, isHitDummyURL = false) {
        this.payload = payload;
        this.isHitDummyURL = isHitDummyURL;
        this.headers = { "access-token": new Cookies("access-token").read() };
    }
    async omsManageList(
        page_id: number,
        search?: string,
        dateRange?:any,
        toParam="",
        fromParam="",
        order?:any,
        statusCode?:any,
        limit = LIST_LIMIT.OMS_MANAGE_LIST_LIMIT,
        
    ) {
        const response = this.isHitDummyURL
            ? await new AxiosUtils(
                `${mockAPIPath.OMS_MANAGE_ORDER}`,
                this.payload,
                this.headers
            ).get()
            : await new AxiosUtils(
                `${API.BASE_URL}${serverAPIPath.OMS_MANAGE_ORDER}?page=${page_id}&search=${search}&dateRange=${dateRange}&to=${toParam}&from=${fromParam}&order=${order}&statusCode=${statusCode}&limit=${limit}`,
                this.payload,
                this.headers
            ).get();
        if (response?.data?.success) {
            const { data, currentPage, totalPages, currentTotalCount, totalCount, totalBackOrderCount, totalPreparingShipmentCount } =
        response.data.data;
            
            const OmsData: OMSTypeWithPaginationType = {
                omsLogs: data,
                pagination: {
                    currentPage,
                    totalPages,
                    currentTotalCount,
                    totalCount,
                },
                totalBackOrderCount: totalBackOrderCount,
                totalPreparingShipmentCount: totalPreparingShipmentCount
            };
            return OmsData;
        } else if (response?.success == false) {
            throw new Error(response?.message);
        } else {
            throw new Error(response);
        }
    }

    
    async omsCancelledList(
        page_id: number,
        search?: string,
        dateRange?:any,
        toParam="",
        fromParam="",
        order?:any,
        statusCode?:any,
        limit = LIST_LIMIT.OMS_MANAGE_LIST_LIMIT,
        
    ) {
        const response = this.isHitDummyURL
            ? await new AxiosUtils(
                `${mockAPIPath.OMS_MANAGE_ORDER}`,
                this.payload,
                this.headers
            ).get()
            : await new AxiosUtils(
                `${API.BASE_URL}${serverAPIPath.OMS_MANAGE_ORDER}?page=${page_id}&search=${search}&dateRange=${dateRange}&to=${toParam}&from=${fromParam}&order=${order}&statusCode=${statusCode}&limit=${limit}`,
                this.payload,
                this.headers
            ).get();
        if (response?.data?.success) {
            const { data, currentPage, totalPages, currentTotalCount, totalCount, totalBackOrderCount, totalPreparingShipmentCount } =
        response.data.data;
            const OmsData: OMSTypeWithPaginationType = {
                omsLogs: data,
                pagination: {
                    currentPage,
                    totalPages,
                    currentTotalCount,
                    totalCount,
                },
                totalBackOrderCount: totalBackOrderCount,
                totalPreparingShipmentCount: totalPreparingShipmentCount
            };
            return OmsData;
        } else if (response?.success == false) {
            throw new Error(response?.message);
        } else {
            throw new Error(response);
        }
    }

    async omsRefundOrderList(
        page_id: number,
        limit = LIST_LIMIT.OMS_MANAGE_LIST_LIMIT,
        search= ""
    ) {
        const response = this.isHitDummyURL
            ? await new AxiosUtils(
                `${mockAPIPath.OMS_REFUND_ORDER}`,
                this.payload,
                this.headers
            ).get()
            : await new AxiosUtils(
                `${API.BASE_URL}${serverAPIPath.OMS_REFUND_ORDER}?page=${page_id}&limit=${limit}&search=${search}`,
                this.payload,
                this.headers
            ).get();
        if (response?.data?.success) {
            const { data, currentPage, totalPages, currentTotalCount, totalCount, totalBackOrderCount, totalPreparingShipmentCount } =
        response.data.data;
            const OmsData: RefundedOrderInterfaceWithPaginationType = {
                refundedOrderData: data,
                pagination: {
                    currentPage,
                    totalPages,
                    currentTotalCount,
                    totalCount,
                },
                totalBackOrderCount: totalBackOrderCount,
                totalPreparingShipmentCount: totalPreparingShipmentCount
            };
            return OmsData;
        } else if (response?.success == false) {
            throw new Error(response?.message);
        } else {
            throw new Error(response);
        }
    }
    async confirmShipmentList(
        page_id: number,
        limit = LIST_LIMIT.OMS_MANAGE_LIST_LIMIT
    ) {
        const response = this.isHitDummyURL
            ? await new AxiosUtils(
                `${mockAPIPath.OMS_MANAGE_ORDER}`,
                this.payload,
                this.headers
            ).get()
            : await new AxiosUtils(
                `${API.BASE_URL}${serverAPIPath.OMS_MANAGE_ORDER}?page=${page_id}&limit=${limit}`,
                this.payload,
                this.headers
            ).get();
        if (response?.data?.success) {
            const { data, currentPage, totalPages, currentTotalCount, totalCount, totalBackOrderCount, totalPreparingShipmentCount } =
        response.data.data;
            const OmsData: OMSTypeWithPaginationType = {
                omsLogs: data,
                pagination: {
                    currentPage,
                    totalPages,
                    currentTotalCount,
                    totalCount,
                },
                totalBackOrderCount: totalBackOrderCount,
                totalPreparingShipmentCount: totalPreparingShipmentCount
            };
            return OmsData;
        } else if (response?.success == false) {
            throw new Error(response?.message);
        } else {
            throw new Error(response);
        }
    }

    async omsPreparingShipmentList(
        page_id: number,
        search?: string,
        dateRange?:any,
        toParam="",
        fromParam="",
        order?:any,
        statusCode?:any,
        limit = LIST_LIMIT.OMS_MANAGE_LIST_LIMIT
    ) {
        const response = this.isHitDummyURL
            ? await new AxiosUtils(
                `${mockAPIPath.OMS_MANAGE_ORDER}`,
                this.payload,
                this.headers
            ).get()
            : await new AxiosUtils(
                `${API.BASE_URL}${serverAPIPath.OMS_MANAGE_ORDER}?page=${page_id}&search=${search}&dateRange=${dateRange}&to=${toParam}&from=${fromParam}&order=${order}&statusCode=${statusCode}&limit=${limit}`,
                this.payload,
                this.headers
            ).get();
        if (response?.data?.success) {
            const { data, currentPage, totalPages, currentTotalCount, totalCount, totalBackOrderCount, totalPreparingShipmentCount } =
        response.data.data;
            const OmsData: OMSTypeWithPaginationType = {
                omsLogs: data,
                pagination: {
                    currentPage,
                    totalPages,
                    currentTotalCount,
                    totalCount,
                },
                totalBackOrderCount: totalBackOrderCount,
                totalPreparingShipmentCount: totalPreparingShipmentCount
            };
            return OmsData;
        } else if (response?.success == false) {
            throw new Error(response?.message);
        } else {
            throw new Error(response);
        }
    }

    async omsMultipleShipmentList(
        page_id: number,
        search?: string,
        dateRange?:any,
        order?:any,
        statusCode?:any,
        limit = LIST_LIMIT.OMS_MANAGE_LIST_LIMIT
    ) {
        const response = this.isHitDummyURL
            ? await new AxiosUtils(
                `${mockAPIPath.OMS_MANAGE_ORDER}`,
                this.payload,
                this.headers
            ).get()
            : await new AxiosUtils(
                `${API.BASE_URL}${serverAPIPath.OMS_MANAGE_ORDER}?page=${page_id}&search=${search}&dateRange=${dateRange}&order=${order}&statusCode=${statusCode}&limit=${limit}`,
                this.payload,
                this.headers
            ).get();
        if (response?.data?.success) {
            const { data, currentPage, totalPages, currentTotalCount, totalCount, totalBackOrderCount, totalPreparingShipmentCount } =
        response.data.data;
            const OmsData: OMSTypeWithPaginationType = {
                omsLogs: data,
                pagination: {
                    currentPage,
                    totalPages,
                    currentTotalCount,
                    totalCount,
                },
                totalBackOrderCount: totalBackOrderCount,
                totalPreparingShipmentCount: totalPreparingShipmentCount
            };
            return OmsData;
        } else if (response?.success == false) {
            throw new Error(response?.message);
        } else {
            throw new Error(response);
        }
    }

    async omsConfirmShipment(orderID:any) {
        const response = this.isHitDummyURL
            ? await new AxiosUtils(
                `${mockAPIPath.CONFIRM_SHIPMENT}`,
                this.payload,
                this.headers
            ).get()
            : await new AxiosUtils(`${API.BASE_URL}${serverAPIPath.CONFIRM_SHIPMENT}${orderID}`, this.payload, this.headers).get();
        if (response?.data?.success) {
            const OmsConfirmData: OMSType = response?.data?.data;
            return OmsConfirmData;
           
        } else if (response?.success == false) {
            throw new Error(response?.message);
        } else {
            throw new Error(response);
        }
    } 


    async omsConfirmShipmentPrintSlip(
        
    ) {
        const response = this.isHitDummyURL
            ? await new AxiosUtils(
                `${mockAPIPath.CONFIRM_SHIPMENT}`,
                this.payload,
                this.headers
            ).get()
            : await new AxiosUtils(
                `${API.BASE_URL}${serverAPIPath.CONFIRM_SHIPMENT}`,
                this.payload,
                this.headers
            ).get();
        if (response?.data?.success) {
            const { data, currentPage, totalPages, currentTotalCount, totalCount, totalBackOrderCount, totalPreparingShipmentCount } =
        response.data.data;
            
            const OmsData: OMSTypeWithPaginationType = {
                omsLogs: data,
                pagination: {
                    currentPage,
                    totalPages,
                    currentTotalCount,
                    totalCount,
                },
                totalBackOrderCount: totalBackOrderCount,
                totalPreparingShipmentCount: totalPreparingShipmentCount
            };
            return OmsData;
        } else if (response?.success == false) {
            throw new Error(response?.message);
        } else {
            throw new Error(response);
        }
    }

    // async omsConfirmShipmentPrintSlip() {
    //     const response = this.isHitDummyURL
    //         ? await new AxiosUtils(
    //             `${mockAPIPath.OMS_MANAGE_ORDER}`,
    //             this.payload,
    //             this.headers
    //         ).get()
    //         : await new AxiosUtils(
    //             `${API.BASE_URL}${serverAPIPath.OMS_MANAGE_ORDER}`,
    //             this.payload,
    //             this.headers
    //         ).get();
    //     if (response?.data?.success) {
    //         const OmsConfirmData: OMSType = response?.data?.data;
    //         return OmsConfirmData;
    //     } else if (response?.success == false) {
    //         throw new Error(response?.message);
    //     } else {
    //         throw new Error(response);
    //     }
    // }

    async omsSaveConfirmShipment() {
        const response = this.isHitDummyURL
            ? await new AxiosUtils(
                `${mockAPIPath.SAVE_CONFIRM_SHIPMENT}`,
                this.payload,
                this.headers
            ).get()
            : await new AxiosUtils(
                `${API.BASE_URL}${serverAPIPath.SAVE_CONFIRM_SHIPMENT}`,
                this.payload,
                this.headers
            ).get();
        if (response?.data?.success) {
            const OmsSaveConfirmData = response?.data?.message;
            return OmsSaveConfirmData;
        } else if (response?.success == false) {
            throw new Error(response?.message);
        } else {
            throw new Error(response);
        }
    }

    async omsEditConfirmShipment() {
        const response = this.isHitDummyURL
            ? await new AxiosUtils(
                `${mockAPIPath.SAVE_CONFIRM_SHIPMENT}`,
                this.payload,
                this.headers
            ).get()
            : await new AxiosUtils(
                `${API.BASE_URL}${serverAPIPath.SAVE_CONFIRM_SHIPMENT}`,
                this.payload,
                this.headers
            ).get();
        if (response?.data?.success) {
            const OmsSaveConfirmData = response?.data?.message;
            return OmsSaveConfirmData;
        } else if (response?.success == false) {
            throw new Error(response?.message);
        } else {
            throw new Error(response);
        }
    }
}

export default OmsManageOrderService;
