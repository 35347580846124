import React, { useState, useRef } from "react";
import {
    Container,
    Grid,
    Box,
    Typography,
    Button,
    Chip,
} from "@mui/material";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { useTranslation } from "react-i18next";
import CsvfileUpload from "../services/inventoryfileUpload";
import SimpleReactValidator from "simple-react-validator";
import { useAppDispatch } from "../../../redux/hooks";
import { commonActions } from "../../../redux/features/common/commonSlice";
import Excel from "exceljs";
import CONSTANTS from "../../../constants/constants";
import CommonButton from "../../common/Button";
import Utility from "../../../utils/Utility";
import Papa from "papaparse";
import Encoding from "encoding-japanese";



const FileUpload = (props: any) => {
    const { handleLoader, showSnackbar } = props;
    const [openSnackBar, setOpenSnackBar] = useState(false);
    const [selectedFileType, setSelectedFileType] = useState("");
    const [file, setFile] = useState<any>("");
    const [fileType, setFileType] = useState<any>("");
    const [fileName, setFileName] = useState<string>("");
    const [resetDialogBox, setResetDialogBox] = useState(false);
    const [isFileUpload, setIsFileUpload] = useState(false);
    const [formSubmit, setFormSubmit] = useState(false);
    const allowFiles = ["application/vnd.openxmlformats-officedocument.spreadsheetml.sheet", "application/vnd.ms-excel", "text/csv"];
    const dispatch = useAppDispatch();
    const fileInputRef = useRef<HTMLInputElement>(null);
    const utility = new Utility();

    const handleCloseSnackBar = () => {
        setOpenSnackBar(false);
    };
    const { t, i18n } = useTranslation();
    const [, forceUpdate] = useState(0);
    const validator2 = useRef(
        new SimpleReactValidator({
            autoForceUpdate: { forceUpdate },
            className: "text-danger",
            messages: {
                max: t("Maximum 40 Character"),
                required: t("required.please_choose_file"),
            },
        })
    );
    /* Language translate function*/

    /* Language translate function*/

    /* File Upload api function*/


    const uploadedFile = (e: any) => {
        setResetDialogBox(false);
        isFileUpload ? alert(t("manage_inventory.file_can_not_be_uploaded")) : InventoryFileuploadData(e);
    };

    const workSheetExcel = () => {
        dispatch(commonActions.SAVE_OPEN_PROGRESSBAR_INVENTORY(false));
        dispatch(commonActions.IS_RELOAD(false));
        setIsFileUpload(false);
        if (fileInputRef.current) {
            fileInputRef.current.value = "";
        }
        setFile("");
        setFileName("");
        setSelectedFileType("");
        setFileType("");
    };

    const fileUploadApi = (payload: any, isHitDummyURL: any) => {
        new CsvfileUpload(payload, isHitDummyURL)
            .csvfile()
            .then((res: any) => {
                handleLoader(false);
                dispatch(commonActions.PROGRESSBAR_MESSAGE_INVENTORY(1));
                dispatch(commonActions.IS_RELOAD(false));
                showSnackbar(res, true);
                setFormSubmit(false);
                setIsFileUpload(false);
                setFileName("");
                setSelectedFileType("");
                setFileType("");
            })
            .catch((err: any) => {
                handleLoader(false);
                setFileName("");
                setSelectedFileType("");
                setFileType("");
                dispatch(commonActions.PROGRESSBAR_MESSAGE_INVENTORY(2));
                dispatch(commonActions.IS_RELOAD(false));
                showSnackbar(err?.message, false, false);
                setIsFileUpload(false);
                setFormSubmit(false);
            });
        setFile("");
        if (fileInputRef.current) {
            fileInputRef.current.value = "";
        }
    };

    const InventoryFileuploadData = (e: any) => {
        dispatch(commonActions.IS_RELOAD(true));
        setIsFileUpload(true);
        setFormSubmit(true);
        e.preventDefault();
        if (file) {
            dispatch(commonActions.SAVE_OPEN_PROGRESSBAR_INVENTORY(true));
            dispatch(commonActions.SAVE_FILE_NAME_INVENTORY(fileName?.split("\\")[fileName?.split("\\").length - 1]));
            dispatch(commonActions.PROGRESSBAR_MESSAGE_INVENTORY(0));
            const renamedFile = new File([file], utility.convertExtensionToLowerCase(file), { type: file.type });
            const payload = { file: renamedFile, inventoryType: selectedFileType };
            const isHitDummyURL = false;
            const workbook = new Excel.Workbook();
            if ((file?.type == "text/csv" || (file?.type === "application/vnd.ms-excel" && navigator.userAgent.includes("Firefox") )) && (selectedFileType == "CM" || selectedFileType == "NE")) {
                const reader = new FileReader();
                reader.onload = function (event: any) {
                    const csvData = event.target.result;
                    const unicodeString = Encoding.convert(new Uint8Array(csvData), {
                        to: "UNICODE",
                        from: "AUTO",
                        type: "string"
                    });
                    Papa.parse(unicodeString, {
                        skipEmptyLines: true,
                        complete: function (results: any) {
                            const rows = results.data;
                            const headers = rows[0];
                            const columnCount = rows[0]?.length;
                            const rowCount = rows.length;
                            const rowItem = Object.values(headers);
                            if (rowCount === 1) {
                                showSnackbar(t("bulkproductupload.no_data_found_in_excel"), false, false);
                                workSheetExcel();
                            } else if (columnCount < 2) {
                                showSnackbar(t("bulkproductupload.invalid_file_template"), false, false);
                                workSheetExcel();
                            } else {
                                let match = true;

                                if (selectedFileType == "NE") {
                                    const hasMandatoryHeaders = CONSTANTS.NE_SHEET_HEADER.every((item: any) => rowItem.includes(item));
                                    const hasOptionalHeader = CONSTANTS.NE_OPTIONAL_HEADERS.some((item: any) => rowItem.includes(item));
                                    if (!hasOptionalHeader) {
                                        match = false;
                                        showSnackbar(t("bulkproductupload.uploaded_file_and_their_type_is_not_matched"), false, false);
                                        workSheetExcel();
                                    }
                                    if (rowCount > CONSTANTS.MAX_FILE_UPLOAD_ROWS_CSV) {
                                        match = false;
                                        showSnackbar(t("bulkproductupload.maximum_row_limit_csv"), false, false);
                                        workSheetExcel();
                                    } else if (!hasMandatoryHeaders || !hasOptionalHeader) {
                                        match = false;
                                        showSnackbar(t("bulkproductupload.invalid_file_template"), false, false);
                                        workSheetExcel();
                                    }
                                    if (match) {
                                        fileUploadApi(payload, isHitDummyURL);
                                    }
                                } else if (selectedFileType == "CM") {
                                    const mandatoryHeadersCM = CONSTANTS.CM_SHEET_HEADER.every((item: any) => rowItem.includes(item));
                                    const optionalHeaderCM = CONSTANTS.CM_OPTIONAL_HEADERS.some((item: any) => rowItem.includes(item));

                                    if (!optionalHeaderCM) {
                                        match = false;
                                        showSnackbar(t("bulkproductupload.uploaded_file_and_their_type_is_not_matched"), false, false);
                                        workSheetExcel();
                                    }

                                    if (rowCount > CONSTANTS.MAX_FILE_UPLOAD_ROWS_CSV) {
                                        match = false;
                                        showSnackbar(t("bulkproductupload.maximum_row_limit_csv"), false, false);
                                        workSheetExcel();
                                    } else if (!mandatoryHeadersCM || !optionalHeaderCM) {
                                        match = false;
                                        showSnackbar(t("bulkproductupload.invalid_file_template"), false, false);
                                        workSheetExcel();
                                    }
                                    if (match) {
                                        fileUploadApi(payload, isHitDummyURL);
                                    }
                                } else {
                                    showSnackbar(t("bulkproductupload.uploaded_file_and_their_type_is_not_matched"), false, false);
                                    workSheetExcel();
                                }
                            }
                        },
                        error: (err: any) => {
                            showSnackbar((t("bulkproductupload.invalid_file_template"), err), false, false);
                            workSheetExcel();
                        }
                    });
                };
                reader.readAsArrayBuffer(file);
            } else if (file.type == "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" && selectedFileType == "CAINZ") {
                workbook.xlsx.load(file)
                    .then(function () {
                        const worksheet = workbook?.worksheets[0];
                        const columnCount = worksheet?.columnCount;
                        const rowCount = worksheet?.actualRowCount;
                        const row = worksheet?.getRow(1).values;
                        const rowItem = Object.values(row);
                        if (rowCount > CONSTANTS.MAX_FILE_UPLOAD_ROWS) {
                            showSnackbar(t("bulkproductupload.maximum_row_limit"), false, false);
                            workSheetExcel();
                        } else if (rowCount == 1) {
                            showSnackbar(t("bulkproductupload.no_data_found_in_excel"), false, false);
                            workSheetExcel();
                        } else if (columnCount < 2) {
                            showSnackbar(t("bulkproductupload.invalid_file_template"), false, false);
                            workSheetExcel();
                        } else {
                            let match = true;
                            CONSTANTS.INVENTORY_SHEET_HEADER.map((item: any) => {
                                const newRowItems = rowItem.filter((current: any) => {
                                    return current == item;
                                });
                                if (newRowItems.length !== 1) {
                                    match = false;
                                    showSnackbar(t("bulkproductupload.invalid_file_template"), false, false);
                                    workSheetExcel();
                                }
                            });
                            for (let rowNumber = 2; rowNumber <= rowCount; rowNumber++) {
                                const row = worksheet.getRow(rowNumber);
                                let isErrorFormula = false;
                                for (let colNumber = 1; colNumber <= columnCount; colNumber++) {
                                    const cell = row.getCell(colNumber);
                                    if (cell.formula) {
                                        const cellValue: any = cell.result;
                                        if (cellValue?.error) {
                                            isErrorFormula = true;
                                            break;
                                        }
                                    }
                                }
                                if (isErrorFormula) {
                                    match = false;
                                    showSnackbar(t("bulkproductupload.file_contains_invalid_function"), false, false);
                                    workSheetExcel();
                                    break;
                                }
                            }
                            if (match) {
                                fileUploadApi(payload, isHitDummyURL);
                            }
                        }
                    });
            } else {
                showSnackbar(t("bulkproductupload.uploaded_file_and_their_type_is_not_matched"), false, false);
                workSheetExcel();
            }
        } else {
            setIsFileUpload(false);
            dispatch(commonActions.IS_RELOAD(false));
            validator2.current.showMessages();
        }
    };

    /* File Upload api function*/

    const handleOnChange = (e: any) => {
        const file = e.target.files[0];
        setFileType(file.type);
        if (e.target.files.length != 0) {
            if (e.target.files[0].size < 10000000) {
                const fileName = file.name.split(".")[0];
                if (!fileName) {
                    showSnackbar(t("imageupload.invalid_file_name"), false, false);
                    setFileName("");
                    setSelectedFileType("");
                    setFileType("");
                    setFile("");
                    if (fileInputRef.current) {
                        fileInputRef.current.value = "";
                    }
                    return;
                }
                if (file.type == "application/vnd.ms-excel" && !navigator.userAgent.includes("Firefox")) {
                    showSnackbar(t("bulkproductupload.invalid_file_type"), false, false);
                    setFileName("");
                    setFile("");
                    if (fileInputRef.current) {
                        fileInputRef.current.value = "";
                    }
                    return;
                }
                if (allowFiles.includes(e.target.files[0].type)) {
                    setFile(e.target.files[0]);
                    setFileName(e.target.files[0].name);
                    setResetDialogBox(true);

                } else {
                    setFileName("");
                    showSnackbar(t("bulkproductupload.invalid_file_type"), false, false);
                    setFile("");
                    if (fileInputRef.current) {
                        fileInputRef.current.value = "";
                    }
                }
            } else {
                setFileName("");
                showSnackbar(t("bulkproductupload.file_size_10_mb"), false, false);
                setFile("");
                if (fileInputRef.current) {
                    fileInputRef.current.value = "";
                }
            }
        }
    };
    function clickFileButton() {
        const button = document.getElementById("file-upload-input-inventory");
        button?.click();
        setSelectedFileType("");
    }
    const handleDragOver = (event: any) => {
        event.preventDefault();
    };

    const handleDrop = (e: any) => {
        const file = e.dataTransfer.files[0];
        setFileType(file.type);
        e.preventDefault();
        if (e.dataTransfer.files.length != 0) {
            if (e.dataTransfer.files[0].size < 10000000) {
                const fileName = file.name.split(".")[0];
                if (!fileName) {
                    showSnackbar(t("imageupload.invalid_file_name"), false, false);
                    setFileName("");
                    setFile("");
                    if (fileInputRef.current) {
                        fileInputRef.current.value = "";
                    }
                    return;
                }
                if (file.type == "application/vnd.ms-excel") {
                    showSnackbar(t("bulkproductupload.invalid_file_type"), false, false);
                    setFileName("");
                    setFile("");
                    if (fileInputRef.current) {
                        fileInputRef.current.value = "";
                    }
                    return;
                }
                if (allowFiles.includes(e.dataTransfer.files[0].type)) {
                    setFile(e.dataTransfer.files[0]);
                    setFileName(e.dataTransfer.files[0].name);
                    setResetDialogBox(true);

                } else {
                    setFileName("");
                    setSelectedFileType("");
                    setFileType("");
                    showSnackbar(t("bulkproductupload.invalid_file_type"), false, false);
                    setFile("");
                    if (fileInputRef.current) {
                        fileInputRef.current.value = "";
                    }
                }
            } else {
                setFileName("");
                setSelectedFileType("");
                setFileType("");
                showSnackbar(t("bulkproductupload.file_size_10_mb"), false, false);
                setFile("");
                if (fileInputRef.current) {
                    fileInputRef.current.value = "";
                }
            }
        }
    };

    const handleSelectFileType = (file: any) => {
        setSelectedFileType(file);
    };

    const resetUploadFile = () => {
        setFileName("");
        setFile("");
        setResetDialogBox(false);
        setSelectedFileType("");
        setFileType("");
        if (fileInputRef.current) {
            fileInputRef.current.value = "";
        }

    };
    const replaceFileUpload = () => {
        const button = document.getElementById("file-upload-input-inventory");
        button?.click();
    };
    return (
        <>
            <Container sx={{ my: 2 }}>
                <Box sx={{ my: 2 }}>
                    <Typography > {t("bulk_product_index.inventory_upload_message")}</Typography>
                    {/* <Typography> {t("bulk_product_index.how_to_download_a_template_and_how_to_upload_it_back_to_the_system")}</Typography> */}
                </Box>
                <Grid container>
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                        <Box className="file-border"
                            onDragOver={handleDragOver}
                            onDrop={handleDrop}
                        >
                            <Box onClick={() => { clickFileButton(); }} className="cursor-pointer">
                                <Typography>
                                    <CloudUploadIcon
                                        className="primary download-upload-icon"
                                    // onChange={(e) => handleOnChange(e)}
                                    />
                                </Typography>
                                <p
                                    onChange={(e) => handleOnChange(e)}
                                >  {fileName ? fileName : ""}
                                    <input className="test" id="file-upload-input-inventory" accept=".xlsx, .xls, .csv" type="file" readOnly hidden ref={fileInputRef} />
                                </p>
                                <Typography>{t("bulk_product_index.please_select_file")}</Typography>
                                {/* {validator2.current.message("store name", file, ["required"])} */}
                                {!fileName && formSubmit ? validator2.current.message("store name", fileName, ["required"]) : null}
                            </Box>
                            {resetDialogBox ?
                                <Box className="file_upload_btn_box">
                                    <Button onClick={replaceFileUpload} className="file_upload_replace_btn" variant="contained">
                                        {t("bulk_product_index.replace")}
                                    </Button>
                                    <Button onClick={resetUploadFile} className="file_upload_reset_btn" variant="contained">
                                        {t("bulk_product_index.reset")}
                                    </Button>
                                </Box> : ""}

                        </Box>
                        <Grid container xs={12} sm={12} md={12} lg={12} className="upload-btn-grid">
                            <Grid item xs={12} sm={12} md={12} lg={12} className="upload-btn-grid" >
                                <Typography>{t("bulk_product_index.select_inventory_file_type")}</Typography>
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} lg={12} className="upload-btn-grid">
                                <Chip
                                    disabled={fileType == "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" ? false : true}
                                    label="CAINZ"
                                    color={selectedFileType === "CAINZ" ? "primary" : "default"}
                                    onClick={() => handleSelectFileType("CAINZ")}
                                    sx={{ mr: 1, cursor: "pointer", width: "150px" }}
                                />
                                <Chip
                                    disabled={fileType === "text/csv" || (fileType==="application/vnd.ms-excel" && navigator.userAgent.includes("Firefox")) ? false : true}
                                    label="NEXT ENGINE"
                                    color={selectedFileType == "NE" ? "primary" : "default"}
                                    onClick={() => handleSelectFileType("NE")}
                                    sx={{ mr: 1, cursor: "pointer", width: "150px" }}
                                />
                                <Chip
                                    disabled={fileType == "text/csv" || (fileType==="application/vnd.ms-excel" && navigator.userAgent.includes("Firefox")) ? false : true}
                                    label="CROSS MALL"
                                    color={selectedFileType === "CM" ? "primary" : "default"}
                                    onClick={() => handleSelectFileType("CM")}
                                    sx={{ cursor: "pointer", width: "150px" }}
                                />

                            </Grid>
                            <Grid item xs={12} sm={12} md={12} lg={12} className="upload-btn-grid" >
                                <CommonButton route={window.location.pathname} functionToExecute={uploadedFile} className="upload-btn" btnName={t("bulk_product_index.upload")} disableCondition={selectedFileType ? false : true} />
                            </Grid>
                            {/* <Button onClick={uploadedFile} className="upload-btn" variant="contained">
                                {t("bulk_product_index.upload")}
                            </Button> */}
                        </Grid>
                    </Grid>
                </Grid>

            </Container>
            <Container>
                <Grid container>
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                        <Box sx={{ my: 4 }} className="color-grey">
                            <Typography sx={{ pb: 2 }}>
                                {t("bulk_product_index.bulk_product_index_guideline_1")}
                            </Typography>
                            <Typography>
                                {t("bulk_product_index.bulk_product_index_guideline_2")}
                            </Typography>
                            <Typography ml={2} sx={{ pb: 2 }}>
                                {t("bulk_product_index.bulk_product_index_guideline_3")}
                            </Typography>
                            <Typography>
                                {t("bulk_product_index.bulk_product_index_guideline_4")}
                            </Typography>
                            <Typography ml={2}>
                                {t("bulk_product_index.bulk_product_index_guideline_5")}
                            </Typography>
                            <Typography ml={2}>
                                {t("bulk_product_index.bulk_product_index_guideline_6")}
                            </Typography>
                            <Typography ml={2} sx={{ pb: 2 }}>
                                {t("bulk_product_index.bulk_product_index_guideline_7")}
                            </Typography>
                            <Typography>
                                {t("bulk_product_index.bulk_product_index_guideline_8")}
                            </Typography>
                            <Typography ml={2}>
                                {t("bulk_product_index.bulk_product_index_guideline_9")}
                            </Typography>
                            <Typography  ml={2}>
                                {t("bulk_product_index.bulk_product_index_guideline_10")}
                            </Typography>
                            <Typography ml={4}>
                                {t("bulk_product_index.bulk_product_index_guideline_11")}
                            </Typography>
                            <Typography ml={4}>
                                {t("bulk_product_index.bulk_product_index_guideline_12")}
                            </Typography>
                            <Typography ml={4}>
                                {t("bulk_product_index.bulk_product_index_guideline_13")}
                            </Typography>
                        </Box>
                    </Grid>
                </Grid>
            </Container>
        </>
    );
};

export default FileUpload;
