import React, { useState, useEffect } from "react";
import {
    Container,
    Grid,
    Typography,
    Card,
    Button,
    Select,
    MenuItem,
    Box,
    InputLabel,
    FormControl,
    CircularProgress,
} from "@mui/material";
import SellerReports from "../services/sellerReports";
import SellerDownload from "../services/sellerDownload";
import { useTranslation } from "react-i18next";
import Pagination from "../../common/Pagination";
import * as XLSX from "xlsx";
import SellerReportinfo from "../services/sellerInfoReport";
import Skeleton from "../../common/Skeleton";
import MUIDataTable from "mui-datatables";
import CONSTANTS, { LIST_LIMIT, SHEET_NAME } from "../../../constants/constants";
import SellerReportServices from "../services/sellerInfoReport";
import downloadDataToExcel from "../../../utils/ExcelDownloadUtility";
import { useAppSelector } from "../../../redux/hooks";
import Utility from "../../../utils/Utility";
import DownloadButton from "../../common/DownloadBtn";

const Reports = (props: any) => {

    const firstPage = 1;
    const perPageLimit = useAppSelector((state) => state.common.perPageLimit);

    const [t, i18n] = useTranslation();
    const { handleLoader,showSnackbar } = props;
    const [reports, setReports] = useState([]);
    const [limit, setLimit] = useState(!perPageLimit ? LIST_LIMIT.BULK_PRODUCT_UPLOAD_REPORTS : perPageLimit );
    const [currentPage, setCurrentPage] = useState(firstPage);
    const [toggle, setToggle] = useState(true);
    const [latestSellerInfo, setLatestSellerInfo] = useState<any>([]);
    const [sellerInfo, setSellerInfo] = useState<any>([]);
    const [appliedFilter, setAppliedFilter] = useState<number>(0);
    const [skeleton, setSkeleton] = useState(true);
    const [totalCount, setTotalCount] = useState<any>(0);
    const [totalPages, setTotalPages] = useState<any>(0);
    const [downloadBtnId,setDownloadBtnId]=useState<any>([]);
    const apiData: any[][] = [];

    const filterMenu = [
        t("reports.all_reports"),
        t("reports.completed"),
        t("reports.error"),
    ];

    useEffect(() => {
        latestReportData();
        // SellerReportsData(currentPage);
    }, []);


    const latestReportData = () => {
        const payload = {};
        const isHitDummyURL = false;
        setSkeleton(true);    
        new SellerReportinfo(payload, isHitDummyURL)
            .LatestSellerReportsData()
            .then((res) => {
                setLimit(limit);
                setSkeleton(false); 
                setLatestSellerInfo(res);
            })
            .catch((err) =>showSnackbar(err?.message, false));
    };

    const ReportsData = () => {
        const payload = {};
        const isHitDummyURL = false;
        new SellerReports(payload, isHitDummyURL)
            .sellerReportsData()
            .then((res: any) => setReports(res.data.data))
            .catch((err: any) =>showSnackbar(err?.message, false));
    };
    /* Seller api function*/

    /* download api function*/

    const DownloadsData = () => {
        const payload = {};
        const isHitDummyURL = false;
        new SellerDownload(payload, isHitDummyURL)
            .sellerDownloadData()
            .then((res) => setReports(res.data.data))
            .catch((err: any) =>showSnackbar(err?.message, false));
    };
    const CsvResponse = (id: number) => {
        const tempDownloadBtnIdArr =[...downloadBtnId];
        tempDownloadBtnIdArr.push(id);
        setDownloadBtnId(tempDownloadBtnIdArr);
        // handleLoader(true);
        const payload = {};
        const isHitDummyURL = false;
        new SellerReportinfo(payload, isHitDummyURL)
            .dowloadImageErrorReport(id)
            .then((res: any) => {
                setDownloadBtnId((prevDownloadBtnId: number[]) => {
                    const removeTempDownloadId = prevDownloadBtnId.indexOf(id);
                    const tempDownloadBtnId = [...prevDownloadBtnId];
                    tempDownloadBtnId.splice(removeTempDownloadId, 1);
                    return tempDownloadBtnId;
                });
                handleLoader(false);
                handleOnExport(res);
            })
            .catch((err: any) => {
                setDownloadBtnId((prevDownloadBtnId: number[]) => {
                    const removeTempDownloadId = prevDownloadBtnId.indexOf(id);
                    const tempDownloadBtnId = [...prevDownloadBtnId];
                    tempDownloadBtnId.splice(removeTempDownloadId, 1);
                    return tempDownloadBtnId;
                });
                showSnackbar(err?.message, false);
            });
    };
    const handleOnExport = (downloadArray?: any) => {
        const uploadfileError: any = [];
        downloadArray?.errors?.map((reportcontent: any)=>{
            uploadfileError.push({[t("reports.errors")]:reportcontent});
        });
        // const wb = XLSX.utils.book_new(),
        //     ws = XLSX.utils.json_to_sheet(uploadfileError);
        // XLSX.utils.book_append_sheet(wb, ws, "エラー内容");
        // XLSX.writeFile(wb, `${reportdownload?.file_name?.split(".")[0]}.xlsx`);
        const fileName =`${downloadArray?.file_name?.split(".")[0]}__エラー内容.xlsx`;
        const allignment = true;
        downloadDataToExcel(uploadfileError,fileName,SHEET_NAME.BULK_IMAGE_UPLOAD_REPORTS,"",allignment);

    };

    /* download api function*/

    useEffect(() => {
        SellerReportsData(firstPage, limit);
    }, [appliedFilter]);

    /*seller info reports function*/

    const SellerReportsData = (currentPage: any, limit: any) => {
        setSkeleton(true);
        const payload = {};
        const isHitDummyURL = false;
        new SellerReportServices(payload, isHitDummyURL)
            .SellerReportsData(currentPage, limit, appliedFilter)
            .then(
                (res) => {
                    setSkeleton(false),
                    setSellerInfo(res),
                    setLimit(limit);
                    setCurrentPage(currentPage);
                    setTotalCount(res?.pagination?.totalCount);
                    setTotalPages(res?.pagination?.totalPages);
                }
            )
            .catch((err: any) => {
                showSnackbar(err?.message, false);
                setSkeleton(false);
            });
        apiTableData();
    };


    const handleFilterChange = (e: any) => {
        const { value } = e.target;
        setAppliedFilter(Number(value));
    };

    const columns = [
        {
            name: t("reports.update_date_time"),
            options: {
                filter: true,
                sort: false,
            },
        },
        {
            name: t("reports.file_name"),
            options: {
                filter: false,
                sort: false,
            },
        },
        // {
        //     name: t("reports.batch_id"),
        //     options: {
        //         filter: true,
        //         sort: false,
        //     },
        // },
        {
            name: t("reports.status"),
            options: {
                filter: true,
                sort: false,
            },
        },
        {
            name: t("reports.action"),
            options: {
                filter: false,
                sort: false,
            },
        },
    ];

    const actionButton = (item: any, productFailedCount: number, productSuccessCount: number) => {
        return (
            <>
                <Box>
                    <DownloadButton loading={downloadBtnId.includes(item.id)} route={window.location.pathname} functionToExecute={() => CsvResponse(item.id)} className="table_btn" btnName={t("reports.download")} disableCondition={downloadBtnId.includes(item.id) ? true : productFailedCount == 0 && productSuccessCount > 0 ? true : item.status==0 ? true : false} />
                    {/* <Button
                        className="table_btn"
                        variant="contained"
                        onClick={() => CsvResponse(id)}
                        disabled={downloadBtnId.includes(id) ? true : productFailedCount ? false : true}
                    >
                        {t("reports.download")}{downloadBtnId.includes(id)  && (
                            <CircularProgress
                                size={24}
                                sx={{
                                    position: "absolute",
                                    top: "50%",
                                    left: "50%",
                                    marginTop: "-12px",
                                    marginLeft: "-12px",
                                }}
                            />
                        )}
                    </Button> */}
                </Box>
            </>
        );
    };

    const apiTableData = () => {
        {
            sellerInfo?.bulkImageUploadReports?.map((item: any, index: number) => {
                const status =item.status==2 ?t("reports.failed") :item.status==0 ?t("reports.in_progress") :
                    item.productFailedCount == 0 && item.productSuccessCount > 0
                        ? t("reports.completed") : t("reports.error");
                // item.productSuccessCount === 0  && item.productFailedCount === 0 ? t("reports.error")
                //     : t("reports.error");
                apiData[index] = [];
                apiData[index].push(
                    item?.uploadDateTime,
                    item?.fileName,
                    // item?.batchId,
                    status,
                    actionButton(item, item.productFailedCount, item.productSuccessCount)
                );
            });
        }
    };
    apiTableData();

    return (
        <>
            <Container sx={{ my: 2 }}>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={6} md={6} lg={4}>
                        <Card className="report_card">
                            <Grid container spacing={2} sx={{ px: 2, my: 4 }}>
                                <Grid item xs={12} sm={6} md={4} lg={4}>
                                    <Typography className="report-card-number" component={"span"}>{new Utility().numberWithCommas(latestSellerInfo?.totalCount)}</Typography>
                                </Grid>
                                <Grid item xs={12} sm={6} md={8} lg={8}>
                                    <Typography className="report-carrd-title">
                                        <Typography component={"span"}>{t("bulk_image_upload.total_product_images")}</Typography>
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Card>
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} lg={4}>
                        <Card className="report_card">
                            <Grid container spacing={2} sx={{ px: 2, my: 4 }}>
                                <Grid item xs={12} sm={6} md={4} lg={4}>
                                    <Typography className="report-card-number" component={"span"}>{new Utility().numberWithCommas(latestSellerInfo?.productApproved)}</Typography>
                                </Grid>
                                <Grid item xs={12} sm={6} md={8} lg={8}>
                                    <Typography className="report-carrd-title">
                                        <Typography component={"span"}>{t("bulk_image_upload.updated_product_images")}</Typography>
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Card>
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} lg={4}>
                        <Card className="report_card">
                            <Grid container spacing={2} sx={{ px: 2, my: 4 }}>
                                <Grid item xs={12} sm={6} md={4} lg={4}>
                                    <Typography className="report-card-number" component={"span"}>{new Utility().numberWithCommas(latestSellerInfo?.errorCount)}</Typography>
                                </Grid>
                                <Grid item xs={12} sm={6} md={8} lg={8}>
                                    <Typography className="report-carrd-title">
                                        <Typography component={"span"}>{t("reports.bulk_image_errors_found")}</Typography>
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Card>
                    </Grid>
                </Grid>
            </Container>
            <Container sx={{ my: 4 }}>
                <Grid container spacing={0} sx={{ mb: 5 }}>
                    <Grid
                        item
                        xs={12}
                        sm={4}
                        md={3}
                        lg={2}
                        sx={{ px: 2, m: "auto", my: 1 }}
                    />
                    <Grid
                        item
                        xs={12}
                        sm={0}
                        md={3}
                        lg={8}
                        sx={{
                            px: 2,
                            display: { xs: "none", sm: "none", md: "flex", lg: "flex" },
                        }}
                    />
                    <Grid
                        item
                        xs={12}
                        sm={4}
                        md={3}
                        lg={2}
                        sx={{ px: 2, m: "auto", my: 1 }}
                    >
                        <FormControl fullWidth>
                            <InputLabel id="demo-simple-select-label"> {t("reports.filter_by_status")} </InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                size="small"
                                label={t("reports.filter_by_status")}
                                value={appliedFilter}
                                onChange={(e) => handleFilterChange(e)}
                                fullWidth
                            >
                                {filterMenu?.map((item, index) => (
                                    <MenuItem key={index} value={index}>
                                        {item}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>
                </Grid>
            </Container>
            <Container>
                {skeleton ? (
                    <Skeleton
                        varient="rectangular"
                        width={0}
                        height={60}
                        count="10"
                        my={0}
                    />
                ) : (
                    <div className="main report_table">
                        <MUIDataTable
                            title={""}
                            columns={columns}
                            data={apiData}
                            options={{
                                pagination: false,
                                print: false,
                                download: false,
                                filter: false,
                                search: false,
                                viewColumns:false,
                                selectableRowsHideCheckboxes: true,
                                textLabels: {
                                    body: {
                                        noMatch: t("table_text.no_match").toString(),
                                    },                                
                                    toolbar: {
                                        search: t("table_text.search").toString(),
                                        viewColumns: t("table_text.view_columns").toString(),
                                    },
                                },
                                searchPlaceholder: t(
                                    "table_text.search_placeholder"
                                ).toString(),
                                responsive: "standard",
                            }}
                        />
                        <Pagination
                            totalCount={totalCount}
                            currentPage={currentPage}
                            pageChange={SellerReportsData}
                            totalPage={totalPages}
                            rowsPerPage={limit}
                            rowsPerPageChange={setLimit}
                        />
                    </div>
                )}
            </Container>
        </>
    );
};

export default Reports;
