import React, { useEffect, useState,useRef } from "react";
import {
    Button,
    Container,
    Typography,
    Box,
    Grid,
    Divider,
    Checkbox,
    TableContainer,
    CardContent,
    Card,
    Paper,
    TableRow,
    TableHead,
    TableCell,
    TableBody,
    Table,
    TextField,InputAdornment,
    IconButton
} from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import CONSTANTS, { LIST_LIMIT, OMS_CONSTANTS, PARAMS_CONSTANTS } from "../../../constants/constants";
import OmsManageOrderService from "../service/OmsManageOrderService";
import { useTranslation } from "react-i18next";
import Pagination from "../../common/Pagination";
import { useNavigate } from "react-router";
import { ROUTES } from "../../../constants/routes";
import Skeleton from "../../common/Skeleton";
import ReportProblemIcon from "@mui/icons-material/ReportProblem";
import SearchIcon from "@mui/icons-material/Search";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { Link } from "react-router-dom";
import Utility from "../../../utils/Utility";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import SimpleReactValidator from "simple-react-validator";
import { commonActions } from "../../../redux/features/common/commonSlice";
import lazyTypingUtils from "../../../utils/lazyTypingUtils";
import jaLocale from "date-fns/locale/ja";


const Cancelled = (props: any) => {
    const { showSnackbar, setBackOrderedCount, setPreparingShipmentCount } = props;
    const cancelledFilter = useAppSelector((state:any) => state?.common?.backOrderFilter);
    const omsCurrentPage = useAppSelector((state:any) => state.common.cancelled);
    const perPageLimit = useAppSelector((state) => state.common.perPageLimit);

    const defaultLimit=LIST_LIMIT.LIMIT_15;
    const firstPage = CONSTANTS.FIRST_PAGE;
    const dispatch = useAppDispatch();
    const [t, i18n] = useTranslation();
    const [skeleton, setSkeleton] = useState(true);
    const [currentPage, setCurrentPage] = useState<any>(omsCurrentPage?.currentPage ?? 1);
    const [totalCount, setTotalCount] = useState<any>(0);
    const [totalPages, setTotalPages] = useState<any>(0);
    // const [limit, setLimit] = useState(omsCurrentPage?.limit);
    const [limit, setLimit] = useState(!perPageLimit ? defaultLimit : perPageLimit );
    const [statusCode, setStatusCode] = useState<any>(
        cancelledFilter?.statusCode ? cancelledFilter?.statusCode : ""
    );
    const [omsData, setOmsData] = useState<any>([]);
    const [isInvitationSent, setIsInvitationSent] = useState(false);
    const [, forceUpdate] = useState(0);
    const [dateRang, setDateRang] = useState<any>(cancelledFilter?.dateRang ?? 7);
    const [shipDate, setShipDate] = useState(cancelledFilter?.shipDate ? cancelledFilter?.shipDate : "ASC");
    const [search, setSearch] = useState<string>(cancelledFilter?.search?cancelledFilter?.search : "");
    const [valueFrom, setValueFrom] = React.useState<Date | null>(cancelledFilter?.valueFrom ? cancelledFilter?.valueFrom : null);
    const [valueTo, setValueTo] = React.useState<Date | null>(cancelledFilter?.valueTo ? cancelledFilter?.valueTo : null);
    const [viewCustom,setViewCustom] =useState<boolean>(cancelledFilter?.viewCustom ? cancelledFilter?.viewCustom : false);
    const [fromParam,setFromParam] = useState<any>(cancelledFilter?.fromParam ? cancelledFilter?.fromParam : "");
    const [toParam,setToParam] = useState<any>(cancelledFilter?.toParam ? cancelledFilter?.toParam : "");
    const [filterChange, setFilterChange] = useState(false);


   

    const validator = useRef(
        new SimpleReactValidator({
            autoForceUpdate: { forceUpdate },
            className: "text-danger",
            messages: {             
                required: t("required.this_field_cannot_be_empty"),
            },
        })
    );
    useEffect(() => {
        forceUpdate(1);
    });
    useEffect(() => {
        const handlePageRefresh = () => {
            dispatch(commonActions.OMS_ORDER_FILTER_REMEMBER({}));
            dispatch(commonActions.IS_UNSHIPPED({currentPage: 1, limit: 15}));
            dispatch(commonActions.IS_SHIPPED({currentPage: 1, limit: 15}));
            dispatch(commonActions.IS_PREPARING_SHIPPMENT({currentPage: 1, limit: 15}));
            dispatch(commonActions.IS_CANCEL({currentPage: 1, limit: 15}));
            dispatch(commonActions.IS_REFUND({currentPage: 1, limit: 15}));
            dispatch(commonActions.IS_DOWNLOAD({ currentPage: 1, limit: 15 }));
        };

        window.addEventListener("beforeunload", handlePageRefresh);

        return () => {
            window.removeEventListener("beforeunload", handlePageRefresh);
        };
    }, []);

    const orderIdCompare: any[] = [];

    const [checked, setChecked] = useState(new Array(limit).fill(false));

    // const handleCheckAllChange = (e: any) => {
    //     e.target.checked == true
    //         ? setChecked(new Array(i).fill(true))
    //         : setChecked(new Array(i).fill(false));
    // };

    const handleCheckboxChange = (index: any) => (event: any) => {
        const newChecked = [...checked];
        newChecked[index] = event.target.checked;
        setChecked(newChecked);
    };
    function onSumbit() {
        navigate(ROUTES.SHIPPING_MULTIPLE_ORDER);
    }

    const navigate = useNavigate();

    useEffect(() => {
        omsManagetData(currentPage ,limit);
        totalPages != 0 ? setOmsData(currentPage) : null;
    }, []);


    useEffect(() => {
        if (isInvitationSent || dateRang || shipDate) {
            if(filterChange){
                omsManagetData(firstPage,limit);
            }
        }
    }, [isInvitationSent, dateRang,shipDate]);

    const onsearchApicall = () =>{
        if(filterChange || search !==""){
            omsManagetData(firstPage,limit);
        }
    };
    
    const propItem = [
        onsearchApicall,search
    ];
    lazyTypingUtils(propItem);


    const omsStoreId = useAppSelector((state) => state?.common?.loggedInStoreId);

    const omsManagetData = (currentPage: number , limit: number) => {
        setFilterChange(false);
        setSkeleton(true);
        const payload = {};
        const isHitDummyURL = false;
        new OmsManageOrderService(payload, isHitDummyURL)
            .omsCancelledList(currentPage,search.trim(),dateRang,toParam,fromParam,shipDate,OMS_CONSTANTS.STATUS_CANCELLED,limit)
            .then((res) => {
                if(res?.pagination?.totalCount>=0){
                    
                    setSkeleton(false);
                }
                setOmsData(res?.omsLogs);
                setCurrentPage(currentPage);
                setTotalCount(res?.pagination?.totalCount);
                setTotalPages(res?.pagination?.totalPages);
                setLimit(limit);
                dispatch(commonActions.IS_CANCEL({currentPage: currentPage,limit: limit}));
                setBackOrderedCount(res?.totalBackOrderCount);
                setPreparingShipmentCount(res?.totalPreparingShipmentCount);
            })
            .catch((err: any) => {
                showSnackbar(err?.message, false);
            });
    };


    const handleSumbit = () => {
        navigate(ROUTES.OMS_CONFIRM_SHIPMENT);
    };

    const handleSlip = (orderId: any) => {
        const orderData = [];
        orderData.push(orderId);
        navigate(ROUTES.PRINT_PACKING_SLIP, { state: { orderId: orderData } });
    };

    const handleAllSlip = (checkList: any) => {
        const orderData2 = [];
       

        for (let index = 0; index < checkList.length; index++) {
            if (checkList[index]) {
                orderData2.push(orderIdCompare.filter((e: any , index: number)=>  orderIdCompare.indexOf(e) === index)[index]); 
            }
        }
        const orderData = orderData2.filter((item: any)=>item!=undefined);

        if (checked.filter((e) => e == true).length > 0) {
            navigate(ROUTES.PRINT_PACKING_SLIP, {
                state: { orderId: orderData },
            });
        }

        else {
            alert("Please Select at least one order");
        }
    };

    const handleSelectRange = (event: SelectChangeEvent) => {
        setFilterChange(true);
        setChecked(new Array(checked.length).fill(false));
        if(event.target.value=="custom"){
            setViewCustom(true);
        }else{
            setViewCustom(false);
            setFromParam("");
            setToParam("");
            setValueFrom(null);
            setValueTo(null);
            setDateRang(event.target.value);
        }
    };

    const handleSelectShipDate = (event: SelectChangeEvent) => {
        setFilterChange(true);
        setShipDate(event.target.value);
    };


    const handleSearchChange = (e: any) => {
        setFilterChange(true);
        setChecked(new Array(checked.length).fill(false));
        const { value } = e.target;
        setSearch(value);
    };
    const handleDateTo = (value:any) =>{
        const [day, month, year] = value.toLocaleDateString("en-GB").split("/");
        setToParam(`${year}/${month}/${day}`);
        setValueTo(value);
    };
    const handleDateFrom = (value:any) =>{
        const [day, month, year] = value.toLocaleDateString("en-GB").split("/");
        setFromParam(`${year}/${month}/${day}`);       
        setValueFrom(value);
    };
    const handleSubmit = () =>{
        if (validator.current.allValid()) {
            setDateRang("");
            if(dateRang==""){
                const data={
                    search,
                    shipDate,
                    dateRang,
                    fromParam,
                    toParam,
                    statusCode,
                    viewCustom,
                    valueFrom,
                    valueTo
                };
                dispatch(commonActions.OMS_ORDER_FILTER_REMEMBER(data));
                omsManagetData(currentPage, limit);
            }      
        }
        else {
            validator.current.showMessages();
        }
    };
    useEffect(()=>{
        const data={
            search,
            shipDate,
            dateRang,
            fromParam,
            toParam,
            statusCode,
            viewCustom,
            valueFrom,
            valueTo
        };
        dispatch(commonActions.OMS_ORDER_FILTER_REMEMBER(data));
    },[search,shipDate,dateRang]);

    const resetAllFilter = () =>{
        setFilterChange(true);
        setViewCustom(false);
        setFromParam("");
        setToParam("");
        setValueFrom(null);
        setValueTo(null);
        setDateRang("7");
        setShipDate("ASC");
        setSearch("");      
    };
    const shouldDisableDateTo = (date:any) => {
        if (valueFrom) {
            return date < valueFrom;
        }
        return false;
    };
    const shouldDisableDateFrom = (date:any) => {
        if (valueTo) {
            return date > valueTo;
        }
        return false;
    };

    // let i = 0;
    localStorage.setItem("panel","2,1,3,4");

    return (
        <>
            <Container>
                <Grid container spacing={2} sx={{ mb: 5 }}>
                    <Grid item xs={12} sm={6} md={4} lg={3} sx={{ py: 1, px: 2 }}>
                        <Typography className="oms-shipped-text"><Box component="span" className="oms_text_margin">{totalCount}</Box>
                            {dateRang === "" ? `${t("oms.order_in_past_period")}` : `${t("oms.order_in_last")} ${dateRang} ${t("oms.days")}`}
                        </Typography>                                   
                            
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} lg={3}>
                        <TextField
                            // id="input-with-icon-textfield"
                            label={t("oms.search_by_order_id")}
                            onChange={handleSearchChange}
                            // onKeyUp={handleKeyPress}
                            value={search}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton>
                                            <SearchIcon />
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}
                            variant="outlined"
                            fullWidth
                            size="small"
                        />
                    </Grid>
                    <Grid item xs={12} sm={5} md={4} lg={2}>
                        <FormControl size="small" fullWidth>
                            <InputLabel id="demo-simple-select-helper-label" >
                                {t("oms.order_confirmation_date")}                                      
                            </InputLabel>
                            <Select fullWidth
                                className="oms-tab-filters"
                                labelId="demo-simple-select-helper-label"
                                id="demo-simple-select-helper"
                                value={viewCustom?"custom":dateRang}
                                label={t("oms.order_confirmation_date")}
                                onChange={handleSelectRange}
                            >
                                <MenuItem value={7}>{t("oms.last_7_days")}</MenuItem>
                                <MenuItem value={30}>{t("oms.last_30_days")}</MenuItem>
                                <MenuItem value={90}>{t("oms.Last_90_days")}</MenuItem>
                                <MenuItem value={365}>{t("oms.last_year")}</MenuItem>
                                <MenuItem value={"custom"}>{t("oms.custom_date_range")}</MenuItem>
                                        
                            </Select>
                        </FormControl>
                    </Grid>              
                    <Grid item xs={12} sm={4} md={4} lg={2}>
                        <Box>
                            <FormControl size="small" fullWidth>
                                <InputLabel id="demo-simple-select-helper-label">
                                    {t("oms.order_date")}
                                </InputLabel>
                                <Select fullWidth
                                    className="oms-tab-filters"
                                    labelId="demo-simple-select-helper-label"
                                    id="demo-simple-select-helper"
                                    value={shipDate}
                                    label= {t("oms.order_date")}
                                    onChange={handleSelectShipDate}
                                >
                                    <MenuItem value="DESC">{t("oms.descending")}</MenuItem>
                                    <MenuItem value="ASC">{t("oms.ascending")}</MenuItem>
                                            
                                </Select>
                            </FormControl>
                        </Box>
                    </Grid>
                    <Grid item xs={6} sm={3} md={2} lg={2}  className="reset_btn">
                        <Button
                            variant="contained"                           
                            sx={{textTransform: "capitalize"}}
                            onClick={resetAllFilter}
                            className="oms_submit-btn"
                        >
                            {t("pr.reset")}
                        </Button>
                    </Grid>                    
                    {
                        viewCustom ? (
                            <>
                                <Grid item xs={0} sm={0} md={1} lg={6} ></Grid>
                                <Grid item xs={12} sm={12} md={4} lg={6} className="oms-date-picker">
                                    <LocalizationProvider dateAdapter={AdapterDateFns} dateFormats={{ monthAndYear: "yyyy年MM月" }} adapterLocale={jaLocale}>
                                        <Grid className="oms_date_picker_grid">
                                            <DatePicker
                                                // className="date-picker-default"
                                                label={t("pr.from")}
                                                value={valueFrom}
                                                inputFormat="yyyy/MM/dd"
                                                onChange={handleDateFrom}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params} 
                                                        inputProps={{
                                                            ...params.inputProps,
                                                            readOnly: true,
                                                            style: { pointerEvents: "none" },
                                                        }}  

                                                    />)}
                                                autoFocus={true}
                                                disableFuture
                                                shouldDisableDate={shouldDisableDateFrom} 
                                                // maxDate={valueTo}  
                                                showToolbar={false}
                                                componentsProps={{
                                                    actionBar: {
                                                        // The actions will be the same between desktop and mobile
                                                        actions: []
                                                    }
                                                }}

                                            />
                                            {validator.current.message("from", valueFrom, [
                                                "required",
                                            ])}

                                        </Grid>

                                    </LocalizationProvider>
                                    <LocalizationProvider dateAdapter={AdapterDateFns} dateFormats={{ monthAndYear: "yyyy年MM月" }} adapterLocale={jaLocale}>
                                        <Grid className="oms_date_picker_grid">
                                            <DatePicker
                                                className="oms-date-picker-default"
                                                label={t("pr.to")}
                                                value={valueTo}
                                                inputFormat="yyyy/MM/dd"
                                                onChange={handleDateTo}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params} 
                                                        inputProps={{
                                                            ...params.inputProps,
                                                            readOnly: true,
                                                            style: { pointerEvents: "none" },
                                                        }}  

                                                    />)}                                         
                                                autoFocus={true}
                                                disableFuture
                                                shouldDisableDate={shouldDisableDateTo}
                                                // minDate={valueFrom}
                                                showToolbar={false}
                                                componentsProps={{
                                                    actionBar: {
                                                        actions: []
                                                    }
                                                }}                                                                                          
                                            />
                                            {validator.current.message("to", valueTo, [
                                                "required",
                                            ])}

                                        </Grid>                                     
                                    </LocalizationProvider>
                                    <Button
                                        variant="contained"
                                        className="oms_submit-btn"
                                        sx={{textTransform: "capitalize"}}
                                        onClick={handleSubmit}
                                    >
                                        {t("pr.submit")}
                                    </Button>
                                </Grid>
                            </>
                        ) : null
                    }               
                   
                </Grid>
            </Container>

            <Container className="oms_table">
                <Grid container spacing={2}>
                   
                    <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                        {skeleton ? (
                            <Skeleton
                                varient="rectangular"
                                width={0}
                                height={253}
                                count="10"
                            />
                        ) : (
                            <TableContainer component={Paper}>
                                                                     
                               
                                <Table aria-label="simple table">

            
                                    <TableHead className="oms_head">
                                      
                                        <TableRow>
                                            <TableCell>
                                                <Box sx={{my:1}}>
                                                    {/* <Checkbox
                                                        className="oms_head_checkbox"
                                                        checked={
                                                            checked?.filter((e) => e == false).length > 0
                                                                ? false
                                                                : true
                                                        }
                                                        disabled={
                                                            omsData?.length == 0
                                                        }
                                                        onClick={(e) => handleCheckAllChange(e)}
                                                    /> */}

                                                    {t("oms.date")}
                                                </Box>
                                            </TableCell>
                                            <TableCell>
                                                <Box>{t("oms.order_details")}</Box>
                                            </TableCell>
                                            <TableCell>
                                                <Box>{t("oms.product_information")} </Box>
                                            </TableCell>
                                            <TableCell>
                                                <Box>{t("oms.customer_option")}</Box>
                                            </TableCell>
                                            <TableCell className="order_status">
                                                <Box>{t("oms.order_status")}</Box>
                                            </TableCell>
                                            <TableCell>
                                                {/* <Box>{t("oms.action")}</Box> */}
                                            </TableCell>
                                        </TableRow>
                                    </TableHead>
                                    
                                    <TableBody className="oms_table_body">
                                        {omsData
                                        // .filter((e: any) => !orderIdCompare.includes(e.orderDateTime))
                                            ?.map((item: any, idIndex: number) => {
                                                const products = item?.products;
                                                return (
                                                    <TableRow key={idIndex}>
                                                        {/* {!orderIdCompare.includes(item.orderId) ? ( */}
                                                        <TableCell>
                                                            <Typography
                                                                className={"order_date_checkbox " + idIndex}
                                                            >
                                                                {/* <Checkbox
                                                                    checked={checked[i]}
                                                                    onChange={handleCheckboxChange(i++)}
                                                                /> */}
                                                                {/* {<Checkbox checked={checkAll} onClick={(e)=>handleChange(e)}/> } */}

                                                                <Box sx={{display: "inline-grid"}}>
                                                                    <Typography>
                                                                        {t("oms.order_date")} :
                                                                    </Typography>
                                                                    <Typography>
                                                                        {item.orderDate}
                                                                    </Typography>
                                                                    <Typography>
                                                                        {t("oms.order_confirmation_date")} :
                                                                    </Typography>
                                                                    <Typography>
                                                                        {item.orderConfirmationDate}
                                                                    </Typography>
                                                                </Box>
                                                            </Typography>
                                                        </TableCell>
                                                        {/* ) : null} */}
                                                        {/* {omsData
                                                            .filter(
                                                                (e: any) =>
                                                                // e.merchantCode == omsStoreId &&
                                                                    e.orderId == item.orderId &&
                                  // e.merchantCode == item.merchantCode &&
                                  !orderIdCompare.includes(e.orderId)
                                                            )
                                                            ?.map((store: any, storeIndex: number) => {
                                                                orderIdCompare.push(item.orderId);
                                                                return storeIndex == 0 ? ( */}
                                                        <TableCell  colSpan={5}>
                                                            <Table>
                                                                <TableBody>
                                                                    <TableRow className="CEC-border-none">
                                                                        <TableCell>
                                                                            <Box className="oms_table_box">
                                                                                <Typography>{t("oms.order_id")} : </Typography>
                                                                                <Typography className="text_wrap_data">
                                                                                    <Link to={`${ROUTES.OMS_CONFIRM_SHIPMENT}/${item?.orderId}`} className="Order_id_link">
                                                                                        {item?.orderId}
                                                                                    </Link>
                                                                                </Typography>
                                                                                <Typography>
                                                                                    {t("oms.buyer_name")} : </Typography>
                                                                                <Typography className="text_wrap_data">
                                                                                    {item?.ordererName} {item?.nameOfOrderer}
                                                                                </Typography>
                                                                                {/* <Typography>
                                                                                {t("oms.sales_channel")} :
                                                                            </Typography> */}
                                                                                {/* <Typography>
                                                                                {t("oms.cainz_com")}
                                                                            </Typography> */}
                                                                            </Box>
                                                                        </TableCell>
                                                                        <TableCell
                                                                            colSpan={
                                                                                item?.orderId == "3180001000111"
                                                                                    ? 4
                                                                                    : 4
                                                                            }
                                                                        >
                                                                            <Table>
                                                                                <TableBody>
                                                                                    {/* {omsData
                                                                                        .filter(
                                                                                            (e: any) =>
                                                                                                e.orderId == store.orderId
                                                                                            //   &&e.merchantCode == omsStoreId
                                                                                        )
                                                                                        ?.map(
                                                                                            (
                                                                                                product: any,
                                                                                                productIndex: number
                                                                                            ) => {
                                                                                                return ( */}
                                                                                    {products?.map((i:any,productIndex:any)=>
                                                                                        <TableRow
                                                                                            key={
                                                                                                productIndex
                                                                                            }
                                                                                            className="table_border"
                                                                                        >
                                                                                            <TableCell className="table_cell_padding">
                                                                                                <Box className="oms_table_box">
                                                                                                    <img
                                                                                                        src={
                                                                                                            i?.image
                                                                                                        }
                                                                                                        alt=""
                                                                                                    />
                                                                                                    <Typography className="text_wrap_data">
                                                                                                        {
                                                                                                            i?.productName
                                                                                                        }
                                                                                                    </Typography>

                                                                                                    <Typography>
                                                                                                        {t("oms.cpid")} : {i?.cainzProductCode}
                                                                                                    </Typography>
                                                                                               
                                                                                                    <Typography>
                                                                                                        {t( "oms.quantity")} : {i?.salesVolume}
                                                                                                    </Typography>
                                                                                               
                                                                                                    <Typography>
                                                                                                        {t("oms.shipped_quantity")} : {i?.shippedQuantity}
                                                                                                    </Typography>
                                                                                              
                                                                                                    <Typography>
                                                                                                        {t("oms.price_subtotal")} : &yen;{
                                                                                                            new Utility().numberWithCommas(i?.productPriceIncludingTaxSubtotal) 
                                                                                                        }
                                                                                                    </Typography>
                                                                                                    {
                                                                                                        productIndex+1 == products?.length ?
                                                                                                            "":
                                                                                                            <Divider sx={{mt:3}}/>
                                                                                                    }
                                                                                                </Box>
                                                                                            </TableCell>

                                                                                            <TableCell>
                                                                                                <Box className="oms_table_box">
                                                                                                    <Typography>
                                                                                                        {t("oms.cancellation_date")} : </Typography> 
                                                                                                    <Typography className="text_wrap_data">
                                                                                                        {item?.cancellationDateTime}
                                                              
                                                                                                    </Typography>     
                                                                                                </Box>    
                                                                                            </TableCell>
                                                                                        </TableRow>
                                                                                    )
                                                                                    }
                                                                                    
                                                                                </TableBody>
                                                                            </Table>
                                                                        </TableCell>

                                                                        <TableCell>
                                                                            <Box className="table_button">
                                                                                <Button
                                                                                    className="bg_danger order_status"
                                                                                    variant="contained"
                                                                                                
                                                                                >
                                                                                    {t("oms.cancelled")}
                                                                                </Button>
                                                                                         
                                                                                <Typography className="CZ-Cancellation-Error" style={{fontWeight: "bold",marginTop:"10px"}}  >
                                                                                    <ReportProblemIcon color="secondary" />
                                                                                    {item?.canceledBy}</Typography>
                                                                                {
                                                                                    item?.cancelBy ?  <Typography mx="1"> {t("oms.cancellation_reason")} {item?.cancellationReason}</Typography> : ""

                                                                                }
                                                                                {/* <Typography mx="1"> {t("oms.cancellation_reason")} {item?.cancellationReasonId == 1 ? t("oms.out_of_stock") : item?.cancellationReasonId == 2 ? t("oms.pricing_error") : t("oms.other_reasons") }</Typography> */}
                                                                                {/* <Typography mx="1"> {item?.cancellationReasonId == 1 ? t("oms.out_of_stock") : item?.cancellationReasonId == 2 ? t("oms.pricing_error") : t("oms.other_reasons") }</Typography> */}
                                                                            </Box>
                                                                        </TableCell>

                                                                        <TableCell>
                                                                            <Box className="table_button">
                                                                                           
                                                                            </Box>
                                                                        </TableCell>
                                                                    </TableRow>
                                                                </TableBody>
                                                            </Table>
                                                        </TableCell>
                                                        {/* ) : null;
                                                            })} */}
                                                    </TableRow>
                                                );
                                            })}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        )}
                        <Typography align="center" variant="h6">
                            {omsData?.length == 0 ? t("oms.no_order_found") : ""}
                        </Typography>
                    </Grid>
                </Grid>
                <Pagination
                    totalCount={totalCount}
                    currentPage={currentPage}
                    pageChange={omsManagetData}
                    totalPage={totalPages}
                    rowsPerPage={limit}
                    rowsPerPageChange={setLimit}
                />
            </Container>
        </>
    );
};

export default Cancelled;
