import React, { useState, useEffect } from "react";
import {
    Container,
    Typography,
    Button,
    Grid,
    Select,
    MenuItem,
    Card,
    Box,
    InputLabel,
    FormControl,
    SelectChangeEvent,
    CircularProgress,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import Pagination from "../../common/Pagination";
import * as XLSX from "xlsx";
import InventoryReportinfo from "../services/inventoryInfoReport";
import InventoryReports from "../services/inventoryReports";
import InventoryDownload from "../services/inventoryReportDownload";
import Skeleton from "../../common/Skeleton";
import MUIDataTable from "mui-datatables";
import { LIST_LIMIT, SHEET_NAME } from "../../../constants/constants";
import SellerReportsModel from "../../../models/SellerReports/SellerReportsModel";
import { useAppSelector } from "../../../redux/hooks";
import downloadDataToExcel from "../../../utils/ExcelDownloadUtility";
import Utility from "../../../utils/Utility";
import DownloadButton from "../../common/DownloadBtn";
import downloadDataToCSV from "../../../utils/CSVDownloadUtility";

const Reports = (props: any) => {
    /* Language translate function*/
    const [t, i18n] = useTranslation();
    /* Language translate function*/

    const firstPage = 1;

    const { handleLoader ,showSnackbar} = props;

    /* Seller api function*/
    const [reports, setReports] = useState<any>([]);
    const [currentPage, setCurrentPage] = useState(firstPage);
    const [totalCount, setTotalCount] = useState<any>(0);
    const [totalPages, setTotalPages] = useState<any>(0);
    const [toggle, setToggle] = useState(true);
    const [latestSellerInfo, setLatestSellerInfo] = useState<any>();
    const [skeleton, setSkeleton] = useState(true);
    const [fileName, setFileName] = useState<any>();
    const [appliedFilter, setAppliedFilter] = useState<number>(0);
    const [downloadBtnId,setDownloadBtnId]=useState<any>([]);
    const apiData: any[][] = [];
    const [limit, setLimit] = useState(LIST_LIMIT.INVENTORY_REPORTS_LIST_LIMIT);
    const storecode = useAppSelector((state) => state?.common?.loggedInStoreCode) ?? null;


    const columns = [
        {
            name: t("reports.update_date_time"),
            options: {
                filter: true,
                sort: false,
            },
        },
        {
            name: t("reports.file_name"),
            options: {
                filter: false,
                sort: false,
            },
        },
        // {
        //     name: t("reports.batch_id"),
        //     options: {
        //         filter: true,
        //         sort: false,
        //     },
        // },
        {
            name: t("reports.status"),
            options: {
                filter: true,
                sort: false,
            },
        },
        {
            name: t("reports.action"),
            options: {
                filter: true,
                sort: false,
            },
        }
    ];

    const filterMenu = [
        t("reports.all_reports"),
        t("reports.completed"),
        t("reports.error"),
    ];


    useEffect(() => {
        SellerReportsData(firstPage, limit);
    }, [appliedFilter]);

    useEffect(() => {
        latestReportData();
        // SellerReportsData(currentPage);
    }, []);

    

    const latestReportData = () => {
        const payload = {};
        const isHitDummyURL = false;
        new InventoryReportinfo(payload, isHitDummyURL)
            .LatestInventoryReportsData()
            .then((res: any) => (setLatestSellerInfo(res)))
            .catch((err: any) =>showSnackbar(err?.message, false));

    };

    const ReportsData = () => {
        const payload = {};
        const isHitDummyURL = false;
        new InventoryReports(payload, isHitDummyURL)
            .inventoryReportsData()
            .then((res: any) => setReports(res.data))
            .catch((err: any) =>showSnackbar(err?.message, false));
    };

    /* Seller api function*/

    /* download api function*/

    const DownloadsData = (id:number) => {
        const tempDownloadBtnIdArr =[...downloadBtnId];
        tempDownloadBtnIdArr.push(id);
        setDownloadBtnId(tempDownloadBtnIdArr);
        // handleLoader(true);
        const payload = {};
        const isHitDummyURL = false;
        new InventoryDownload(payload, isHitDummyURL)
            .inventoryDownloadData(id)
            .then((res: any) => {
                setDownloadBtnId((prevDownloadBtnId: number[]) => {
                    const removeTempDownloadId = prevDownloadBtnId.indexOf(id);
                    const tempDownloadBtnId = [...prevDownloadBtnId];
                    tempDownloadBtnId.splice(removeTempDownloadId, 1);
                    return tempDownloadBtnId;
                });
                // handleLoader(false);
                handleOnExport(res?.xlData, res?.file_name);
                setFileName(res);
                setReports(res);
            })
            .catch((err: any) => {
                setDownloadBtnId((prevDownloadBtnId: number[]) => {
                    const removeTempDownloadId = prevDownloadBtnId.indexOf(id);
                    const tempDownloadBtnId = [...prevDownloadBtnId];
                    tempDownloadBtnId.splice(removeTempDownloadId, 1);
                    return tempDownloadBtnId;
                });
                showSnackbar(err?.message, false);
            });
    };

    

    const handleOnExport = (downloadArray?: any, downloadFileName?:any) => {
        const date = new Date();
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, "0");
        const day = String(date.getDate()).padStart(2, "0");
        const formattedDate= `${year}${month}${day}`;
        // const wb = XLSX.utils.book_new(),
        //     ws = XLSX.utils.json_to_sheet(reportdownload?.xlData);
        // XLSX.utils.book_append_sheet(wb, ws, "在庫一覧_エラー内容");
        // // XLSX.writeFile(wb, "MyExcel.xlsx");
        // XLSX.writeFile(wb, `在庫一覧_${storecode}_${formattedDate}_エラー内容.xlsx`);
        const numFmt="text";
        const fileName = `在庫一覧_${storecode}_${formattedDate}_エラー内容.xlsx`;
        const allignment = true;
        downloadDataToExcel(downloadArray?.xlData,fileName,SHEET_NAME.MANAGE_INVENTORY_REPORTS, numFmt, allignment);
        
    };
    /* download api function*/

    /*seller info reports function*/
    const [sellerInfo, setSellerInfo] = useState<any>([]);

    const SellerReportsData = (currentPage: number, limit: number ) => {
        const payload = {};
        const isHitDummyURL = false;
        setSkeleton(true);
        new InventoryReportinfo(payload, isHitDummyURL)
            .InventoryReportsData(currentPage, limit, appliedFilter)
            .then(
                (res) => (
                    setSellerInfo(res),
                    setLimit(limit),
                    setCurrentPage(res?.pagination.currentPage),
                    setTotalCount(res?.pagination?.totalCount),
                    setTotalPages(res?.pagination?.totalPages),
                    setSkeleton(false)
                    
                )
            )
            .catch((err: any) => {
                setSkeleton(false);
                showSnackbar(err?.message, false);
            });
    };

    // const handleFilterChange = (filter: number) => {
    //     setAppliedFilter(filter);
    //     SellerReportsData(currentPage, limit);
    // };

    const handleFilterChange = (e: any) => {
        const { value } = e.target;
        setAppliedFilter(Number(value));
        // SellerReportsData(currentPage, limit,  value);
    };

    const actionButton = (id: number, productFailedCount: any) => {
        return (
            <>
                <DownloadButton loading={downloadBtnId.includes(id)}  route={window.location.pathname} functionToExecute={()=>DownloadsData(id)} className="table_btn" btnName= {t("reports.download")} disableCondition={downloadBtnId.includes(id) ? true : productFailedCount? false : true}/>               
                {/* <Button
                    variant="contained"
                    className="table_btn"
                    onClick={()=>DownloadsData(id)}
                    disabled={downloadBtnId.includes(id) ? true : productFailedCount? false : true}
                >
                    {t("reports.download")}{downloadBtnId.includes(id)  && (
                        <CircularProgress
                            size={24}
                            sx={{
                                position: "absolute",
                                top: "50%",
                                left: "50%",
                                marginTop: "-12px",
                                marginLeft: "-12px",
                            }}
                        />
                    )}
                </Button> */}
                
            </>
        );
    };

    const apiTableData = () => {
        {
            sellerInfo?.sellerReports?.map(
                (item: SellerReportsModel, index: number) => {
                    
                    apiData[index] = [];
                    apiData[index].push(
                        item.uploadDateTime,
                        item.fileName,
                        // item.batchId,
                        item.status==2 ? t("reports.failed") :item.status==0 ?t("reports.in_progress") :item.productFailedCount == 0  ? t("reports.completed") :t("reports.error"),
                        actionButton(item.id, item.productFailedCount)
                    );
                }
            );
        }
    };
    apiTableData();
    
    const [age, setAge] = React.useState("");

    const handleChange = (event: SelectChangeEvent) => {
        setAge(event.target.value as string);
    };

    return (
        <>
            {/* Table header starts here */}
            <Container sx={{ my: 2 }}>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={6} md={6} lg={4}>
                        <Card className="report_card">
                            <Grid container spacing={2} sx={{ px: 2, my: 4 }}>
                                <Grid item xs={12} sm={6} md={4} lg={4}>
                                    <Typography className="report-card-number" component={"span"}>{new Utility().numberWithCommas(latestSellerInfo?.totalCount)}</Typography>
                                </Grid>
                                <Grid item xs={12} sm={6} md={8} lg={8}>
                                    <Typography className="report-carrd-title">
                                        <Typography component={"span"}>{t("reports.total_product")}</Typography>
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Card>
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} lg={4}>
                        <Card className="report_card">
                            <Grid container spacing={2} sx={{ px: 2, my: 4 }}>
                                <Grid item xs={12} sm={6} md={4} lg={4}>
                                    <Typography className="report-card-number" component={"span"}>{new Utility().numberWithCommas(latestSellerInfo?.productApproved)}</Typography>
                                </Grid>
                                <Grid item xs={12} sm={6} md={8} lg={8}>
                                    <Typography className="report-carrd-title">
                                        <Typography component={"span"}>{t("reports.updated_products")}</Typography>
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Card>
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} lg={4}>
                        <Card className="report_card">
                            <Grid container spacing={2} sx={{ px: 2, my: 4 }}>
                                <Grid item xs={12} sm={6} md={4} lg={4}>
                                    <Typography className="report-card-number" component={"span"}>{new Utility().numberWithCommas(latestSellerInfo?.errorCount)}</Typography>
                                </Grid>
                                <Grid item xs={12} sm={6} md={8} lg={8}>
                                    <Typography className="report-carrd-title">
                                        <Typography component={"span"}>{t("reports.errors_found")}</Typography>
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Card>
                    </Grid>
                </Grid>
            </Container>
            <Container sx={{ my: 4 }}>
                <Grid container spacing={0} sx={{ mb: 5 }}>
                    <Grid
                        item
                        xs={12}
                        sm={4}
                        md={3}
                        lg={2}
                        sx={{ px: 2, m: "auto", my: 1 }}
                    />
                    <Grid
                        item
                        xs={12}
                        sm={0}
                        md={3}
                        lg={8}
                        sx={{
                            px: 2,
                            display: { xs: "none", sm: "none", md: "flex", lg: "flex" },
                        }}
                    />
                    <Grid
                        item
                        xs={12}
                        sm={4}
                        md={3}
                        lg={2}
                        sx={{ px: 2, m: "auto", my: 1 }}
                    >
                       
                        <FormControl fullWidth>
                            <InputLabel id="demo-simple-select-label"> {t("reports.filter_by_status")} </InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={appliedFilter}
                                label={t("reports.filter_by_status")}
                                size="small"
                                onChange={(e) =>handleFilterChange(e)}>
                                {filterMenu?.map((item, index) => (
                                    <MenuItem key={index} value={index}>
                                        {item}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>

                    </Grid>
                </Grid>
                <Container className="report_table">
                    {skeleton ? (
                        <Skeleton
                            varient="rectangular"
                            width={0}
                            height={60}
                            count="10"
                            my={0}
                        />
                    ) : (
                        <MUIDataTable 
                       
                            title={""}
                            columns={columns}
                            data={apiData}
                            options={{
                                pagination: false,
                                print: false,
                                download: false,
                                filter: false,
                                search: false,
                                viewColumns:false,
                                selectableRowsHideCheckboxes: true,
                                textLabels: {
                                    body: {
                                        noMatch: t("table_text.no_match").toString(),
                                    },
                                    toolbar: {
                                        search: t("table_text.search").toString(),
                                        viewColumns: t("table_text.view_columns").toString(),
                                    },
                                },
                                searchPlaceholder: t("table_text.search_placeholder").toString(),
                                responsive: "standard",
                                // onSearchChange: (searchText: any) => {
                                //     setSearch(searchText ? searchText : "");
                                // },
                            }}
                        />
                    )}
                </Container>
                {/*pagination starts here*/}
                <Pagination
                    totalCount={totalCount}
                    currentPage={currentPage}
                    pageChange={SellerReportsData}
                    totalPage={totalPages}
                    rowsPerPage={limit}
                    rowsPerPageChange={setLimit}
                />
                {/*pagination starts here*/}
                
            </Container>
        </>
    );
};

export default Reports;
